.caja-item-ponente {
    background: #2F2F40;
    border-radius: 10px;
    text-align: left;
    overflow: hidden;
    max-width: 360px;
}

.caja-item-ponente-nombre{
    color: #DDDDDD;
    cursor: pointer;
    font-size: 18px;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
}
.caja-item-ponente-cargo{
    color: #999999;
    cursor: pointer;
    font-size: 14px;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
}
.caja-item-ponente-empresa{
    color: #999999;
    cursor: pointer;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
}

.caja-item-ponente-contendor-imagen{
    background-color: #000;
    flex-wrap: wrap;
    overflow: hidden;
    position: relative;
    display: flex;
    margin-bottom: 20px;
}


.caja-item-ponente-contendor-imagen .caja-item-etiqueta {
    opacity: .9;
    position: absolute;
    margin: 10px;
}

.caja-item-ponente-imagen{
    width: 275px;
    height: 164px;
}

.caja-item-ponente-body {
    margin-bottom: 10px;
    padding: 0 20px;
}

.caja-item-ponente-contendor-imagen .caja-item-etiqueta{
    color:#000000B3;
}

.caja-item-ponente-etiquetas {
    display: flex;
    margin: 10px 0;
    flex-wrap: wrap;
}

.caja-item-ponente-etiqueta{
    background: #CCCCCC;
    color: #000000;
    font-size: 12px;
    margin-right: 10px;
    padding: 2px 3px;
    border-radius: 3px;
    margin-bottom: 10px;
}

.caja-item-ponente-etiqueta.dark{
    background: #333333;
    color: #999999;
}

.caja-item-ponente-footer {
    padding: 10px 20px 10px 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.caja-item-ponente-like{
    cursor: pointer;
}

.caja-item-ponente-fecha{
    color: #DDDDDD;
    font-size: 12px;
    font-family: 'Lato', sans-serif;
} 
.caja-item-ponente .disponible{
    color: #DDDDDD; 
}
@media (max-width: 500px) {
    .caja-item-ponente-nombre{
        font-size: 0.9rem;
    }
  }

