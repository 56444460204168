
.grid-eventos {
    display: flex;
    flex-wrap: wrap;
  }

.cardEvento {
    position: relative;
    background: #F9F6F1;
    border-radius: 5px;
    box-shadow: 0px 0px 20px #00000065;
    text-align: left;
    overflow: hidden;
    width: 390px;
    height: 550px;
    margin-right:1.5rem;
    box-shadow: 0 2px 2px rgb(0 0 0 / 16%);
    border: 1px solid rgba(0,0,0,.1);
    margin-bottom: 30px;
    transition: all .2s ease-in-out;
}

.cardEvento:hover {
    transform: scale(1.05);
    transition: all .2s ease-in-out;
}

.cardEvento-titulo{
    cursor: initial;
    font-size: 25px;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    min-height: 110px;
    line-height: 30px;
    margin-bottom: 30px;
}
.cardEvento-titulo a{
    color: #3C3C3C;
}

.cardEvento-titulo span{
    color: #3C3C3C;
    cursor: initial;
}

.cardEvento-contendor-imagen{
    flex-wrap: wrap;
    overflow: hidden;
    display: flex;
    margin-bottom: 20px;
}


.cardEvento-contendor-imagen .cardEvento-etiqueta {
    opacity: .9;
    position: absolute;
    margin: 10px;
}

.cardEvento-imagen{
    width: 100%;
    height: 203px;
}
.cardEvento-imagen img{
    object-fit: cover;
    height: 203px;
}

.cardEvento-body {
    margin-bottom: 10px;
    padding: 0 20px;
}

.cardEvento-contendor-imagen .cardEvento-etiqueta{
    color:#000000B3;
}

.cardEvento-etiquetas {
    display: flex;
    margin: 18px 0;
    flex-wrap: wrap;
}

.cardEvento-etiqueta{
    background: #CCCCCC;
    color: #000000;
    font-size: 12px;
    margin-right: 10px;
    padding: 2px 6px;
    border-radius: 3px;
    margin-bottom: 10px;
    letter-spacing: 0.4px;
}
.etiqueta-grey{
    background-color: #333333;
    color: #999999;
}

.cardEvento-etiqueta.dark{
    font-size: 12px;
    font-weight: 400;
    padding: 4px 12px;
    background: rgba(0, 0, 0, .75);
    color: white;
    letter-spacing: 0.4px;
}

.cardEvento-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 10px 20px 10px 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.cardEvento-footer .btn.principal {
    width: 100%;
    padding: 0;
}
.cardEvento-footer .btn.principal a {
    padding: 10px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardEvento-footer .btn.principal span {
    padding: 10px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: initial;
}

.cardEvento-body .emision{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-left: 0px;
    font-size: 16px;
    color: #666;
}

.cardEvento-body .emision .nombre {
    color: #3B619E;
    font-size: 21px;
    font-weight: 400;
    margin-bottom: 5px;
}
.cardEvento-body .emision .info {
    color: #666666;
    font-size: 19px;
    font-weight: 400;
}
.cardEvento-body .eye {
    margin-right: 10px;
}

.cardEvento-like{
    cursor: pointer;
}

.cardEvento-fecha{
    font-size: 15px;
    font-family: 'Lato', sans-serif;
    max-width: 80%;
    font-weight: 400;
} 


.cardEvento-body .card-text {
    color: #666;
    font-size: 16px;
    line-height: 22px;
    font-size: 16px;
    line-height: 22px;
    -webkit-line-clamp: 3;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin: 0 0 10px;
    max-height: 70px;
}

.disponible{
    color: #DDDDDD; 
}
.directo{
    color:#FA1900;
    font-weight: 600;
}
@media (max-width: 500px) {
    .container-carrusel {
        margin: 4rem 2rem;
    }
    .cardEvento {
        width: 100%;
        height: 450px;
    }
    .cardEvento-imagen{
        height: auto;
    }
    .cardEvento-imagen img {
        height: auto;
    }
    .cardEvento-titulo{
        font-size: 20px;
        min-height:60px;
    }
    .cardEvento-etiqueta{
        font-size: 10px;
    }
    .cardEvento-fecha{
        font-size: 12px;
    } 
}

@media (max-width: 768px) {
    .container-carrusel {
        margin: 5px;
    }
    .container-carrusel .titulo{
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 20px;
      }

    .slick-list .cardEvento {
        width: 300px;
        height: 450px;
    }
}

@media (max-width: 880px) {
    .cardEvento {
        width: 100%;
        height: 460px;
        margin-right: 0;
    }

}

