.sectionFooter{
    border-top: #3B619E solid 11px;
    background-color: #fff;
    padding: 15px 0;
    font-family: 'Lato', sans-serif;
}

.logo-footer {
    text-align: center;
    margin: 20px 0;
}

.logo-footer img, .logo-footer svg{
    max-width: 120px;
}

.footer{
    background-color: #fff;
    color: #3C3C3C;
    font-size: 12px;
    padding:0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.footer .titulo{
    text-align: center;
    font-size: 20px;
}

.menuFooter{
    display: flex;
    margin: 0;
    flex-wrap: wrap;
    height: 108px;
    justify-content: center;
    align-items: center;
}
.menuFooter li{
    margin-top: 0;
    list-style-type: none;
}
.menu_footer_logo{
    text-align: center;
    margin: 20px 0 35px 0;
}
.menu_footer_logo img, .menu_footer_logo svg{
    width: 8.5rem;
}

.itemMenuFooter{
    font-size: 16px;
    font-weight: 400;
    margin: 0 5px;
    cursor: pointer;
    letter-spacing: normal;
    color: #666;
}

.itemMenuFooter:before {
    content: "|";
    margin-right: 10px;
    color: #666666;
}

.itemMenuFooter a:hover{
    color: #000;
}

.copyfooter{
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    height: 108px;
    align-items: center;
    margin-left: 80px;

}
.copyfooter a{
    color: #7a7a7a;
    font-weight: 400;
    letter-spacing: 1px;
}
.copyfooter a:hover{
    color: white;
}
.copyfooter img{
    width: 150px;
    margin-top: 15px;
}
.menuFooter_rrss{
    display: flex;
    margin-left: 5px;
}
.menuFooter_rrss a{
    margin-right: 10px;
    margin-top: -6px;
    fill: #7A7A7A;
}
.menuFooter_rrss a:hover{
    fill: white;
}
.menuFooter_rrss img{
    vertical-align:middle;
}

.logos-footer {
    display: flex;
    margin-bottom: 30px;
    margin-top: 30px;
}

@media (max-width: 1437px) {
    .menuFooter_rrss a{
        margin-top: 2px;
    }
}
@media (max-width: 1450px) {
    .menuFooter_rrss a{
        margin-top: -6px;
    }
}
@media (max-width: 1223px) {
    .menuFooter_rrss a{
        margin-top: -8px;
    }
}
@media (max-width: 1004px) {
    .menuFooter_rrss a{
        margin-top: -1px;
    }
}
@media (max-width: 1210px) {
    .itemMenuFooter{
        font-size: 1rem;
    }
    .copyfooter{
        font-size: 0.8rem;
    }
}
@media (max-width: 1000px) {
    .itemMenuFooter{
        line-height: 2rem;
    }
}
@media (max-width: 893px) {
    .menuFooter_rrss a{
        margin-top: 4px;
    }
}
@media (max-width: 880px) {
    .footer {
        flex-direction: column;
    }
    .logos-footer {
        display: flex;
        margin-bottom: 10px;
        margin-top: 20px;
        flex-direction: column;
    }
    .menuFooter{
        flex-direction: column;
        align-items: flex-start;
        height: auto;
        width: 100%;
        margin: 0 60px;
    }
    .menuFooter li {
        margin-top: 0;
        list-style-type: none;
        width: auto;
        margin-left: 20px;
    }
    .copyfooter {
        padding: 0;
        margin: 20px;
        height: auto;
    }
    .itemMenuFooter{
        margin-bottom: 0.3rem;
    }
    .itemMenuFooter:after {
        content: "";
        margin-left: 5px;
    }
}



.logos-footer .junta img {
    width: 214px;
    height: 109px;
}
.logos-footer .upta{
    margin-left: 20px;
}
.logos-footer .upta img {
    width: 158px;
    height: auto;
}