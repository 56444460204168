.grid-canales {
  display: flex;
  margin-top: 80px;
  margin-bottom: 30px;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px;
  justify-content: center;
  align-items: flex-start;
}

.grid-canales .grid-item{
    
  text-align: center;
  width: 300px;
  margin: 15px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #262632 0% 0% no-repeat padding-box;
  border: 2px solid #262632;
  box-shadow: 0px 0px 20px #00000065;
  border-radius: 10px;
}
.grid-canales .grid-item:hover{
    box-shadow: 0px 0px 25px #58a8f5;
    border: 2px solid #27E1F8CC;
    opacity: .8;
}

.grid-canales .grid-item .item-canal {
  background: #262632 0% 0% no-repeat padding-box;
  border: none;
  box-shadow: none;
  border-radius: 10px;
  max-width: 100%;
  text-align: center;
  display: block;
  cursor: pointer;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
}

 
@media (min-width: 575.98px) { 
  .grid-canales{
    display: flex;
    margin-top: 80px;
    margin-bottom: 30px;
    flex-wrap: wrap;
  }  

}

@media (min-width: 767.98px) { 
  .grid-canales{
    display: flex;
    margin-top: 80px;
    margin-bottom: 30px;
    flex-wrap: wrap;
  }  
}

@media (min-width: 991.98px) { 
  .grid-canales{
    display: flex;
    margin-top: 80px;
    margin-bottom: 30px;
    flex-wrap: wrap;
  }
  
}

@media (min-width: 1199.98px) { 
  .grid-canales{
    display: flex;
    margin-top: 80px;
    margin-bottom: 30px;
    flex-wrap: wrap;
  }
  
}

@media (min-width: 1399.98px) { 
  .grid-canales{
    display: flex;
    margin-top: 80px;
    margin-bottom: 30px;
    flex-wrap: wrap;
  }
  
}

  