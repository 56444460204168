.BannerInterior {
  position: relative;
}
.BannerInterior_background img{
  height: 504px;
  object-fit: cover;
  vertical-align: bottom;
}
.banner-interior.BannerInterior .BannerInterior_background img{
  height: 504px;
  object-fit: cover;
  vertical-align: bottom;
}
.BannerInterior_content {
  position: absolute;
  top: 100px;
  left: 150px;
  width: 600px;
}
.banner-interior.BannerInterior.BannerInterior_content {
  top: 50px;
}

.BannerInterior_content img{
  width: 325px;
  margin-bottom: 50px;
}
.BannerInterior_content .titulo{
  color: #FFFFFF;
  font-size: 38px;
  font-weight: 600;
  line-height: 49px;
  margin-bottom: 50px;
}
.BannerInterior_content .subtitulo{
    color: #999999;
    font-size: 19px;
    line-height: 29px;
    font-weight: 600;
    margin-bottom: 50px;
}
.BannerInterior_btn{
  width: 446px;
  background: linear-gradient(90deg, rgba(80,164,251,1) 0%, rgba(0,226,250,1) 100%);
  border: 1px solid #00E2FA;
  border-radius: 5px;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  padding: 10px 50px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}
@media (max-width: 1200px) {
  .BannerInterior_background img{
    object-position: 80%;
  }
}
@media (max-width: 800px) {
  .BannerInterior_content {
    top: 10%;
    left: 10%;
    width: 80%;
  }
}
@media (max-width: 600px) {
  .BannerInterior_background img{
    object-position: 70%;
  }
  .banner2 .item-etiqueta{
    margin-bottom: 5px;
  }
  .BannerInterior_content {
    top: 2%;
  }
  .BannerInterior_content img{
    width: 200px;
    margin-bottom: 25px;
  }
  .BannerInterior_content .titulo{
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 25px;
  }
  .BannerInterior_content .subtitulo{
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 25px;
  }
  .BannerInterior_btn{
    width: 90%;
    font-size: 12px;
    line-height: 15px;
    padding: 10px 20px;
  }
}


.banner2{
  height:504px
}
.banner2 .item-etiqueta{
  background: #CCCCCC;
  color: #000000;
  font-size: 13px;
  margin-right: 5px;
  padding: 3px 4px;
  border-radius: 3px;
}
.banner2 .etiqueta_tipo{
  background:#262632;
  color: #999999;
}
.banner2 .etiqueta_categoria{
  background: #000000;
  color: #CCCCCC;
}
.banner2 .titulo{
  font-size: 38px;
  font-weight: 500;
  margin-bottom: 10px;
}
.banner2 .destacado{
  margin-bottom: 20px;
}
.banner2_btn{
  background: #000;
  border: 1px solid #FFFFFF;
  border-radius: 5px;
  display: inline-block;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  padding: 10px 50px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}
.BannerInterior .homepublica_repuesto_btn{
  position: absolute;
  bottom:60px;
  left: 150px;
  z-index: 10;
}
@media (max-width: 800px) {
  .BannerInterior .homepublica_repuesto_btn {
    left: 10%;
  }
  .banner2 .titulo{
    font-size:28px;
    font-weight: 500;
    margin-bottom: 10px;
    line-height: 32px;
  }
  .banner2_btn{
    font-size: 18px;
    line-height: 24px;
    padding: 8px 50px;
   
  }
}
@media (max-width: 600px) {
  .BannerInterior .homepublica_repuesto_btn {
    width: 350px;
    bottom:120px;
  }
}
@media (max-width: 425px) {
  .BannerInterior .homepublica_repuesto_btn {
    width: 250px;
    bottom:30px;
  }
  .cookiemodal_buttons{
    margin-bottom: 10px;
  }
}