.slider-registrado .slick-slide > div {
  display: flex;
}

.slider-registrado ul.slick-dots {
  position: absolute;
  bottom: 0;
}

.slider-registrado .slick-dots li button:before {
  font-size: 1px;
  line-height: 12px;
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  content: '';
  text-align: center;
  opacity: 1;
  color: transparent;
  border: #FFFFFF80 solid 2px;
  border-radius: 50px;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
  color: #FFFFFF80;
  background: #FFFFFF80;
}

.bannerPrincipal00 {
    position: relative;
    max-width: 100%;
    overflow: hidden;
  }
  
  .bannerPrincipal00 .card-background {
    height: 50vh;
  }
  
  .bannerPrincipal00 .card-background img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  
  .bannerPrincipal00  .card-content {
    position: absolute;
    top: 0;
    margin: auto;
    display: inline-block;
    padding: 100px 0;
    width: 33%;
  }

  .bannerPrincipal00  .card-content .titulo {
    color: #FFFFFF;
    font-size: 38px;
    line-height: 44px;
    margin-bottom: 20px;
  }

  .bannerPrincipal00  .card-content .destacado {
    color: #3B619E;
    font-size: 19px;
    line-height: 29px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .bannerPrincipal00 .caja-etiquetas {
    display: flex;
    margin: 10px 0;
  }

  .bannerPrincipal00 .caja-etiquetas .item-etiqueta{
    background: #CCCCCC;
    color: #000000;
    font-size: 12px;
    margin-right: 10px;
    padding: 2px 3px;
    border-radius: 3px;
}

.bannerPrincipal00 .caja-etiquetas .item-etiqueta.dark{
    background: #333333;
    color: #999999;
}


.bannerInterior {
  position: relative;
  max-width: 100%;
  overflow: hidden;
}

.bannerInterior .card-background {
  height: 50vh;
}

.bannerInterior .card-background img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.bannerInterior  .card-content {
    position: absolute;
    top: 0;
    margin: auto;
    display: inline-block;
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20%;
    flex-direction: column;
}

.bannerInterior  .card-content .titulo {
  color: #FFFFFF;
  font-size: 38px;
  line-height: 44px;
  margin-bottom: 20px;
}

.bannerInterior  .card-content .destacado {
  color: #3B619E;
  font-size: 19px;
  line-height: 29px;
  font-weight: 600;
  margin-bottom: 20px;
}

.bannerInterior .caja-etiquetas {
  display: flex;
  margin: 10px 0;
}

.bannerPrincipal00 .caja-etiquetas .item-etiqueta.dark{
  background: #333333;
  color: #999999;
}

  