.container-carrusel{
  margin: 50px 0;
}
.container-carrusel .titulo{
  font-size: 2.2rem;
  margin-bottom: 20px;
  line-height: 3rem;
  color: #3C3C3C;
  font-size: 52px;
  line-height: 52px;
  font-weight: 300;
  text-align: left;
}

.evento_container .container-carrusel .titulo{
  margin-bottom: 0px;
}

.container-carrusel .titulo span{
  color: #3B619E;
}

.container-carrusel .titulo.colorfull{
  color: #3B619E;
}

.container-carrusel .titulo .lead{
  font-size: .8rem;
  margin-left: 0.5rem;
  color: #999999;
  display: inline-flex;
  justify-content: flex-start;
  align-items: right;
  cursor: pointer;
}
.container-carrusel .titulo .lead:before{
  content: ".";
  margin-right: 0.5rem;
  transform: translateY(-2px);
}
.container-carrusel .titulo .lead:after{
  content: ">";
  margin-left: 0.5rem;
  transform: translateY(1px);
}

.container-carrusel .slick-list .slick-track{
    display: flex !important;
}

.slider-principal .slick-next {
  right: 25px;
}
.slider-principal .slick-prev {
  left: 25px;
}

.slider-principal .slick-dots {
  bottom: 25px;
  width: 100%;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slider-principal .slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 8px;
  height: 8px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: 0;
  background: none;
  border: #fff solid 1px;
  border-radius: 50px;
}
.slider-principal  .slick-dots li.slick-active button {

  background: #fff;
}

.slider-principal  .slick-dots li.slick-active button:before {
  opacity: 0;
  color: transparent;
  background: transparent;
}

.slick-dots li button:before {
 
  color: transparent;
}

@media (max-width: 768px) {
  .container-carrusel {
      margin: 20px 5px;
  }
  .container-carrusel .titulo{
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 20px;
  }
}

