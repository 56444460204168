.listadopreguntas{
  background-color: #262632;
  border-radius: 5px;
  padding: 30px;
  margin-bottom: 70px;
  box-shadow: 0px 0px 20px #000000;
}
.listadopreguntas h2{
  font-size: 24px;
  font-weight: 500;
  border-bottom:1px solid #707070;
}
.cajaChat{
  display: flex;
  flex-direction: column;
  border-bottom:1px solid #707070;
  margin-bottom: 30px;
}
.listadopreguntas .globo{
  background-color: #54A6F8;
  border-radius: 10px 10px 0 10px;
  color: white;
  padding: 10px 15px;
  width: 385px;
}
.listadopreguntas .preguntachat{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-self: flex-end;
}
.listadopreguntas .preguntachat.publico{
  align-items: flex-start;
  align-self: flex-start;
}
.listadopreguntas .preguntachat.publico .globo{
  background-color: #4B4B51;
  border-radius: 10px 10px 10px 0;
  align-items: flex-start;
}
.listadopreguntas .preguntachat.publico p{
  color: white;
  margin-bottom: 0;
  font-size: 14px;
}
.listadopreguntas .preguntachat.publico .globo .nombre{
  display: flex;
  align-self: flex-start;
  font-size: 12px;
  color: #3B619E;
  margin-bottom: 5px;
}
.listadopreguntas .preguntachat.derecha{
  align-items: flex-start;
  align-self: flex-start;
}
.listadopreguntas .preguntachat.derecha .globo{
  background-color: #4B4B51;
  border-radius: 10px 10px 10px 0;
  align-items: flex-start;
}
.listadopreguntas .preguntachat.derecha p{
  color: white;
  margin-bottom: 0;
  font-size: 14px;
}
.listadopreguntas .preguntachat.derecha .globo .nombre{
  display: flex;
  align-self: flex-start;
  font-size: 12px;
  color: #3B619E;
  margin-bottom: 5px;
}
.listadopreguntas .preguntachat .globo{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.listadopreguntas .preguntachat .globo .texto{
    font-size: 14px;
}
.listadopreguntas .preguntachat .globo .nombre{
  display: none;
}
.listadopreguntas .preguntachat .hora{
  font-size: 11px;
  margin:10px 0;
}
.listadopreguntas .rowpreguntar{
  display: flex;
}
.listadopreguntas .rowpreguntar .textareaPreguntas{
  width: 100%;
  margin-right: 25px;
  background-color: #33333E;
  border:none;
  border-radius: 5px;
}
.listadopreguntas .rowpreguntar .textareaPreguntas{
  color: white;
  font-size: 14px;
  padding: 10px;
}
.listadopreguntas .rowpreguntar .textareaPreguntas::placeholder{
  color: white;
  font-size: 14px;
  font-family: 'Lato', sans-serif;
}
.listadopreguntas .rowpreguntar .botoneraPreguntas{
  display: flex;
  flex-direction: column;
}
.listadopreguntas .rowpreguntar .botoneraPreguntas .btn-borrar{
  order: 2;
  background-color: rgba(0,0,0,0);
  border:1px solid #707070;
  font-size: 14px;
  color: #707070;
  font-weight: 500;
  padding: 10px 50px;
}
.listadopreguntas .rowpreguntar .botoneraPreguntas .btn-enviar{
  order: 1;
  background-color: #54A6F8;
  border:none;
  font-size: 18px;
  color: white;
  font-weight: 500;
  margin-bottom: 7px;
  padding: 10px 50px;
}
@media (max-width: 700px) {
    .listadopreguntas .rowpreguntar .botoneraPreguntas .btn-borrar{
        padding: 10px 25px;
        font-size: 12px;
      }
      .listadopreguntas .rowpreguntar .botoneraPreguntas .btn-enviar{
        padding: 10px 25px;
        font-size: 16px;
      }
  }
@media (max-width: 530px) {
    .listadopreguntas{
        padding: 20px;
    }
    .listadopreguntas .rowpreguntar .textareaPreguntas{
        margin-right: 10px;
      }
    .listadopreguntas .globo{
        width: 250px;
      }
      .listadopreguntas .rowpreguntar .botoneraPreguntas .btn-borrar{
        padding: 10px 5px;
        font-size: 10px;
      }
      .listadopreguntas .rowpreguntar .botoneraPreguntas .btn-enviar{
        padding: 10px 5px;
        font-size: 13px;
      }
  }
@media (max-width: 350px) {
    .listadopreguntas .globo{
        width: 100%;
      }
  }