.notfound{
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(249,246,241,1) 0%, rgba(249,246,241,1) 25%, rgba(255,255,255,1) 90%, rgba(249,246,241,1) 100%);
    height: 100vh;
    color: #000;
}
.notfound_content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.notfound_content h2{
    font-weight: 500;
    font-size: 45px;
    margin-bottom: 50px;
    color: #000;
}
.notfound_content h2 span{
    color: #3B619E;
}
.notfound_content p{
    width: 60%;
    text-align: center;
    font-size: 27px;
    line-height: 38px;
    margin-bottom: 50px;
    color: #000;
}
.notfound_content p a{
    color: #3B619E;
    text-decoration: underline;
}