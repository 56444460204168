.container-carrusel{
  margin: 5rem 4rem 4rem 4rem;
}
.container-carrusel .titulo{
  font-size: 2.2rem;
  margin-bottom: 20px;
  line-height: 3rem;
  color: #000;
  font-size: 38px;
  line-height: 49px;
  font-weight: 500;
  text-align: left;
}
.container-carrusel .titulo .lead{
  font-size: .8rem;
  margin-left: 0.5rem;
  color: #999999;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.container-carrusel .titulo .lead:before{
  content: ".";
  margin-right: 0.5rem;
  transform: translateY(-2px);
}
.container-carrusel .titulo .lead:after{
  content: ">";
  margin-left: 0.5rem;
  transform: translateY(1px);
}

.container-carrusel .slick-list .slick-track{
    display: flex !important;
}