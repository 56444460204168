.contacto-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 80px;
    margin-bottom: 100PX;
}
.contacto-container .col1 {
    width: 40%;
    margin-right: 10%;
}

.contacto-container .col2 {
    width: 48%;
    
}

.contacto-container .col1 h2 {
    color: #3B619E;
    font-size: 52px;
    font-weight: 300;
    margin-bottom: 30px;
}

.contacto-container .col1 h3 {
    color: #3C3C3C;
    font-size: 23px;
}

.contacto-container .col1 p {
    font-size: 19px;
    color: #3C3C3C;
    margin-bottom: 10px;
}

.contacto-container .col1 p a{
    color: #3B619E;  
}

.contacto-container .col1 .redes {
    margin-top: 50px;
}

.contacto-container .col1 .redes h2{
    color: #BC122A;
    font-size: 42px;
}

.contacto-container .col1 .redes h2 span{
    color: #666666;
}

.contacto-container .col1 .redes .item-redes {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.contacto-container .col1 .redes .item-redes .icon {
    font-size: 36px;
    color: #CCCCCC;
    margin-right: 20px;
    width: 50px;
    text-align: center;
}
.contacto-container .col1 .redes .item-redes .nombre {
    color: #3C3C3C;
    font-weight: 500;
    font-size: 26px;
}

.contacto-container .col1 .redes .item-redes .enlaceredes:hover .nombre{
  color: #BC122A;
}

.contacto-container .formulario-contacto {
    max-width: 580px;
}
.contacto-container .formulario-contacto .form-contacto{
    width: 100%;
}
.contacto-container .formulario-contacto .row{
    width: 100%;
}
.contacto-container .formulario-contacto .row .col{
    width: 100%;
}

.contacto-container .formulario-contacto .form-group{
    width: 100%;
}
.contacto-container .formulario-contacto .form-group .form-control {
    width: 100%;
    border: #CCCCCC solid 1px;
    font-size: 22px;
    padding: 20px 15px;
    margin-bottom: 10px;
}
.contacto-container .formulario-contacto .text-muted {
    font-size: 15px;
    color: #666666;
    margin-top: -10px;
}

.contacto-container .formulario-contacto .footer-contacto{
    display: flex;
}

.contacto-container .formulario-contacto .footer-contacto .col1 {
    width: 55%;
    margin-right: 0;
}

.contacto-container .formulario-contacto .footer-contacto .col2 {
    width: 45%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.contacto-container .formulario-contacto .footer-contacto .col2 button.btn.btn-primary.btn-form {
    width: 100%;
    font-size: 16px;
    padding: 25px;
    margin-bottom: 5px;
}

.contacto-container .formulario-contacto .footer-contacto-legal {
    margin-top: 30px;
}

.contacto-container .formulario-contacto .footer-contacto-legal .content-legal {
    margin-top: 30px;
    margin-bottom: 50px;
}

.contacto-container .formulario-contacto .footer-contacto-legal .content-legal p {
    color: #666666;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
}

.contacto-container .formulario-contacto .logos .logo1 img {
    width: 160px;
    height: auto;
    margin-bottom: 30px;
}

.enlaceredes{
  display: flex;
}

.enlaceredes:hover{
  color: #3B619E;
}

@media (max-width: 768px) {
    .contacto-container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        margin-top: 30px;
    }

    .contacto-container .col1 {
        width: 100%;
        margin-right: 0;
    }

    .contacto-container .col2{
        width: 100%;
        margin-right: 0;
    }
    .contacto-container .col1 .redes {
        margin-bottom: 50px;
    }
    .contacto-container .col1 .redes .item-redes .icon {
        font-size: 20px;
        color: #CCCCCC;
        margin-right: 20px;
        width: 50px;
        text-align: center;
    }
    .contacto-container .col1 .redes .item-redes .nombre {
        color: #3C3C3C;
        font-weight: 500;
        font-size: 20px;
    }
    .contacto-container .formulario-contacto .footer-contacto {
        display: flex;
        flex-direction: column;
    }
    .contacto-container .formulario-contacto .footer-contacto .col1 {
        width: 100%;
        margin-right: 0;
    }
    .contacto-container .formulario-contacto .footer-contacto .col2 {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        margin-top: 30px;
    }
}

.bannerContacto {
    position: relative;
  }
  .bannerContacto_background img{
    height: 453px; /*570px;*/
    object-fit: cover;
    vertical-align: bottom;
  }
  .banner-interior.bannerContacto .bannerContacto_background img{
    height: 279px;
    object-fit: cover;
    vertical-align: bottom;
  }
  .bannerInterior .bannerContacto_background img{
    height: 279px;
    object-fit: cover;
    vertical-align: bottom;
  }
  .bannerContacto_content {
    position: absolute;
    top: 100px;
    left: 150px;
    width: 600px;
  }
  .banner-interior.bannerContacto.bannerContacto_content {
    top: 50px;
  }
  
  .bannerInterior .bannerContacto_content {
    top: 50px;
  }
  
  .bannerContacto_content img{
    width: 325px;
    margin-bottom: 50px;
  }
  .bannerContacto_content .titulo{
    color: #FFFFFF;
    font-size: 38px;
    font-weight: 600;
    line-height: 49px;
    margin-bottom: 50px;
  }
  .bannerContacto_content .subtitulo{
      color: #999999;
      font-size: 19px;
      line-height: 29px;
      font-weight: 600;
      margin-bottom: 50px;
  }
  .bannerContacto_btn{
    width: 446px;
    background: linear-gradient(90deg, rgba(80,164,251,1) 0%, rgba(0,226,250,1) 100%);
    border: 1px solid #00E2FA;
    border-radius: 5px;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    line-height: 27px;
    padding: 10px 50px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
  }

  .logo1 {
    display: flex;
    width: 100%;
    height: auto;
    margin-bottom: 30px;
  }

  .logo1 img{
    width: auto;
   
  }

  @media (max-width: 1200px) {
    .bannerContacto_background img{
      object-position: 80%;
    }
  }
  @media (max-width: 800px) {
    .bannerContacto_content {
      top: 10%;
      left: 10%;
      width: 80%;
      background: rgb(0,4,9);
      background: linear-gradient(0deg, rgba(0,4,9,1) 0%, rgba(0,7,9,0.8491771708683473) 65%, rgba(0,8,9,0.6671043417366946) 87%, rgba(0,8,9,0) 100%);
      z-index: 99;
      position: relative;
      left: 0;
      width: 100%;
      padding: 5%;
      margin-top: -400px;
      padding-bottom: 40px;
    }
    .slider-principal .slick-dots {
      bottom: 0px!important;
      width: 100%;
      margin: 0;
      list-style: none;
      text-align: center;
    }
    .banner2 .titulo {
      font-size: 30px;
      font-weight: 500;
      line-height: 32px;
      margin-bottom: 10px;
    }
  }
  @media (max-width: 600px) {
    .bannerContacto_background img{
      object-position: 70%;
    }
    .banner2 .item-etiqueta{
      margin-bottom: 5px;
    }
    .bannerContacto_content {
      top: 10%;
      left: 10%;
      width: 80%;
      background: rgb(0,4,9);
      background: linear-gradient(0deg, rgba(0,4,9,1) 0%, rgba(0,7,9,0.8491771708683473) 65%, rgba(0,8,9,0.6671043417366946) 87%, rgba(0,8,9,0) 100%);
      z-index: 99;
      position: relative;
      left: 0;
      width: 100%;
      padding: 5%;
      margin-top: -400px;
      padding-bottom: 40px;
    }
    .bannerContacto_content img{
      width: 200px;
      margin-bottom: 25px;
    }
    .bannerContacto_content .titulo{
      font-size: 30px;
      line-height: 35px;
      margin-bottom: 25px;
    }
    .bannerContacto_content .subtitulo{
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 25px;
    }
    .bannerContacto_btn{
      width: 90%;
      font-size: 12px;
      line-height: 15px;
      padding: 10px 20px;
    }
  }
  
  
  .banner2{
    height:504px
  }
  .banner2 .item-etiqueta{
    background: #CCCCCC;
    color: #000000;
    font-size: 13px;
    margin-right: 5px;
    padding: 3px 4px;
    border-radius: 3px;
  }
  .banner2 .etiqueta_tipo{
    background:#262632;
    color: #999999;
  }
  .banner2 .etiqueta_categoria{
    background: #000000;
    color: #CCCCCC;
  }
  .banner2 .titulo{
    font-size: 38px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .banner2 .destacado{
    margin-bottom: 20px;
  }
  .banner2_btn{
    background: #000;
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    display: inline-block;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    line-height: 27px;
    padding: 10px 50px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
  }
  .bannerContacto .homepublica_repuesto_btn{
    position: absolute;
    bottom:60px;
    left: 150px;
    z-index: 10;
  }
  @media (max-width: 800px) {
    .bannerContacto .homepublica_repuesto_btn {
      left: 10%;
    }
    .banner2{
      height: auto!important;
      top: 0!important;
      padding-top: 200px;
      margin-top: -510px;
    }
    button.slick-arrow {
      display: none!important;
    }
    .bannerInterior .bannerContacto_background img {
      height: 279px;
      object-fit: cover;
      vertical-align: unset;
      object-position: 0 50;
    }
  }
  @media (max-width: 600px) {
    .bannerContacto .homepublica_repuesto_btn {
      width: 350px;
      bottom:120px;
    }
  }
  @media (max-width: 425px) {
    .bannerContacto .homepublica_repuesto_btn {
      width: 250px;
      bottom:30px;
    }
    .cookiemodal_buttons{
      margin-bottom: 10px;
    }
  }
  
  .slick-slider.slider-principal {
  
    background: #000;
  }
  .bannerContacto_container{
    position: absolute;
    top: 0;
    width: 100%;
    left: auto;
    display: flex;
    justify-content: center;
    border-bottom: #ccc solid 1px;
  }
  .banner-contacto .claim-header{
    color: #fff;
    margin: 0;
    width: 100%;
    max-width: 100%;
    height: 453px;
    background: rgb(249,246,241);
    background: linear-gradient(90deg, rgba(249,246,241,1) 0%, rgba(249,246,241,1) 15%, rgba(249,246,241,1) 30%, rgba(249,246,241,0) 100%);
  }
  .banner-contacto .claim-header .content {
    width: 40%;
    margin: 80px;
  }


.bannerContacto_container .claim-header .titulo {
 
    color: #3B619E;
    font-weight: 400;
}

.bannerContacto_container .claim-header .subtitulo {
  
    color: #666666;
}
.imgcontacto{
  border-radius: 20px;
    max-width: 400px;
    height: auto;
}
@media (max-width: 768px){
  .banner-contacto .claim-header .content {
        width: 100%;
        margin: 0;
    }
    .bannerContacto_container .claim-header {
        color: #fff;
        margin: 0!important;
        width: 100%;
        max-width: 550px;
        height: 260px;
    }
    .bannerContacto_container .claim-header .titulo {
      color: #3B619E;
      font-weight: 400;
      font-size: 40px;
      text-align: left;
      width: 100%;
    }
    
    .bannerContacto_container .claim-header .subtitulo {
      
        color: #666666;
        font-size: 30px;
    }
    .bannerContacto_background img {
        height: 260px;
      
    }
}

  .claim-header b{
    color: #666;
  }
  
  .claim-header .titulo{
        font-size: 28px;
        margin-bottom: 15px;
        font-family: 'Roboto', sans-serif;
    }
    .claim-header .subtitulo{
      font-size: 24px;
      margin-bottom: 20px;
      line-height: 26px;
      font-family: 'Lato', sans-serif;
      font-weight: 300;
    }
    .claim-header .boton{
        color:#fff;
        font-size: 28px;
        border: #fff solid 1px;
        padding: 5px 20px;
        margin-top: 20px;
        font-family: 'Lato', sans-serif;
        font-weight: 300;
    }
  
  
  @media (max-width: 992px) {  
    .claim-header {
      color: #fff;
      margin: 0;
      width: 100%;
      height: 300px;
    }
    .claim-header  .titulo{
          font-size: 28px;
          margin-bottom: 15px;
          font-family: 'Lato', sans-serif;
      }
      .claim-header .subtitulo{
        font-size: 24px;
        margin-bottom: 20px;
        line-height: 26px;
        font-family: 'Lato', sans-serif;
        font-weight: 300;
        color: #000;
      }
      .claim-header .boton{
          color:#fff;
          font-size: 24px;
          border: #fff solid 1px;
          padding: 5px 20px;
          margin-top: 20px;
          font-family: 'Lato', sans-serif;
          font-weight: 300;
      }
      .claim-header .content {
        width: 100%;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        padding: 20px;
    }
  }
  
  @media (min-width: 768px) {  
    
    
    .claim-header .titulo{
          font-size: 28px;
          margin-bottom: 15px;
          font-family: 'Roboto', sans-serif;
      }
      .claim-header  .subtitulo{
        font-size: 24px;
        margin-bottom: 20px;
        line-height: 26px;
        font-family: 'Lato', sans-serif;
        font-weight: 300;
        color: #000;
      }
      .claim-header  .boton{
          color:#fff;
          font-size: 24px;
          border: #fff solid 1px;
          padding: 5px 20px;
          margin-top: 20px;
          font-family: 'Lato', sans-serif;
          font-weight: 300;
      }
  }
  
  
  @media (min-width: 992px) { 
    .claim-header{
      color:#000;
      margin: 0;
      max-width: 100%;
      font-weight: 300;
    }
      .claim-header .titulo{
          font-size: 68px;
          line-height: 72px;
          margin-bottom: 15px;
          font-family: 'Roboto', sans-serif;
          font-weight: 400;
          display: flex;
          flex-wrap: wrap;
          
      }
      
      .claim-header .subtitulo{
        font-size: 52px;
        margin-bottom: 20px;
        line-height: 54px;
        font-family: 'Lato', sans-serif;
        font-weight: 300;
        margin-top: 50px;
        color: #3C3C3C;
      }
      .claim-header .boton{
          color:#fff;
          font-size: 24px;
          border: #fff solid 1px;
          padding: 5px 20px;
          margin-top: 20px;
          font-family: 'Lato', sans-serif;
          font-weight: 300;
      }
      .banner-contacto .claim-header .subtitulo{
        max-width: 500px;
      }
  }

  @media (min-width: 1200px) { 
    .claim-header{
      color:#000;
      margin: 0;
      max-width: 100%;
      font-weight: 300;
    }
      .claim-header .titulo{
          font-size: 80px;
          margin-bottom: 15px;
          font-family: 'Roboto', sans-serif;
          font-weight: 400;
          line-height: 90px;
          display: flex;
          flex-wrap: wrap;
          
      }
      
      .claim-header .subtitulo{
        font-size: 52px;
        margin-bottom: 20px;
        line-height: 54px;
        font-family: 'Lato', sans-serif;
        font-weight: 300;
        margin-top: 50px;
        color: #3C3C3C;
      }
      .claim-header .boton{
          color:#fff;
          font-size: 24px;
          border: #fff solid 1px;
          padding: 5px 20px;
          margin-top: 20px;
          font-family: 'Lato', sans-serif;
          font-weight: 300;
      }
      .banner-contacto .claim-header .subtitulo{
        max-width: 500px;
      }
  }

  @media (min-width: 1400px) { 
    .claim-header{
      color:#000;
      margin: 0;
      max-width: 100%;
      font-weight: 300;
    }
      .claim-header .titulo{
          font-size: 115px;
          margin-bottom: 15px;
          font-family: 'Roboto', sans-serif;
          font-weight: 400;
          line-height: 115px;
          display: flex;
          flex-wrap: wrap;
          
      }
      
      .claim-header .subtitulo{
        font-size: 52px;
        margin-bottom: 20px;
        line-height: 54px;
        font-family: 'Lato', sans-serif;
        font-weight: 300;
        margin-top: 50px;
        color: #3C3C3C;
      }
      .claim-header .boton{
          color:#fff;
          font-size: 24px;
          border: #fff solid 1px;
          padding: 5px 20px;
          margin-top: 20px;
          font-family: 'Lato', sans-serif;
          font-weight: 300;
      }
      .banner-contacto .claim-header .subtitulo{
        max-width: 500px;
      }
  }
  
  
  
  