.baseModal {
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: rgba(4, 7, 21, .9);
    position: fixed;
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 999;

}

.modalBody.modalRegistro {
    position: relative;
    max-width: 800px;
    max-height: 80vh;
    padding: 60px;
    background-color: #F9F6F1;
    border: 1px solid #F9F6F1;
    border-radius: 0px;
    box-sizing: border-box;
    margin: 0 15px;
    text-align: left;
}

.formscroll {
    height: 100%;
    max-height: calc(80vh - 90px);
    overflow: auto;
    overflow-x: hidden;
    width: 100%;
    padding: 0 20px 0 0;
}

.modalRegistro .titulo span{
    color: #BC122A;
}

.modalRegistro .formscroll  .selectorInput .selectorInputDropdown.toggle {
    visibility: visible;
    transform: scale(1, 1);
    max-height: 260px;
    overflow: auto;
}

.modalRegistro .formscroll  .selectorInput .selectorInputDropdown.toggle::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  .modalRegistro .formscroll  .selectorInput .selectorInputDropdown.toggle::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  .modalRegistro .formscroll  .selectorInput .selectorInputDropdown.toggle::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  .modalRegistro .formscroll  .selectorInput .selectorInputDropdown.toggle::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

.baseModal.open{
    display: flex;
}
.modalBody.modalRegistro  .modalLogin_cerrar {
    position: absolute;
    top: -35px;
    right: 0;
    font-size: 24px;
    text-transform: capitalize;
    cursor: pointer;
    color: #EFEFEF;
    font-weight: 100;
}
.modalBody .modalRegistro_cerrar:hover{
    color: white;
}
.modalBody .small{
    margin-bottom: 20px;
}
.small{
    font-size: 12px;
}

label.small{
    font-size: 14px;
}


.inputMail{
    text-transform: lowercase;
}
.inputEmpresa{
    text-transform: uppercase;
}

.labelInput{
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    color: #FFF;
}
.boton_registrarse{
    background-color: #54a6f8;
    color: white;
    width: 100%;
    border-radius: 5px;
    border: none;
    padding: 1.3rem 0;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1.2rem;
    margin-top: 30px;
    cursor: pointer;
}
.form-aviso{
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 15px;
}
.form-aviso .small span{
    color: #3B619E;
}
.form-aviso .small a{
    color: #ffffff;
    text-decoration: underline;
}

.step3{
    max-height: 60vh;
    overflow:auto;
    padding: 5px;
    padding-right: 1rem;
}
.step3 .selectorInput .selectorInputBtn{
    font-size: 16px;
    font-weight: 400;
}
.step3 .form-group .small {
    color: white;
    font-size: 18px;
}
.step3 .form-group .small.checkbox_text{
    font-size: 14px;
}
.step3 .form-group{
    margin-bottom: 20px;
    width: 100%;
}
/* width */
.step3::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  .step3::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  .step3::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  .step3::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
.step3_form_title{
    font-size: 0.9rem;
    margin-bottom: 10px;
    display: block;

}
.step3_form_title span{
    color:#3B619E;
}
.step3_separacion{
    padding-right: 1rem;
}
.row1_form_separacion{
    margin-top: 1rem;
}
.step3_col_ancho{
    width: 100%;
}
.step3_col_ancho p{
    font-size: 0.8rem;
    color:#6E6E6E;
    margin: 0;
}
.step3_checkbox{
   margin-bottom: 0.5rem;
}
.step3_checkbox label{
   margin-left: 0.2rem;
}
.step3_checkbox input[type=checkbox] {    
    cursor: pointer;
}
.step3_selector{
    display: flex;
}
.step3_selector_input{
    width: 82%;
    margin-right: 3%;
}
.step3_selector .inputText{
    cursor: pointer;
}
.step3_selector select{
    height: 60px;
    background: #707070;
    border-radius: 5px;
    width: 100%;
    margin: 10px 0;
    color: #fff;
    font-size: 16px;
    padding: 0 50px 0 15px;
    margin-right: 3%;
    cursor: pointer;
    appearance: none;
}
.step3 .boton_registrarse{
    margin-bottom: 10px;
}
.selectorError{
    border: #DB4C4C solid 2px;
}
.step3_selector_icon{
    width: 60px;
    height: 60px;
    margin-top: 10px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px;
    cursor: pointer;
}
.step3_selector_icon span{
    font-family: 'Material Icons';
    color: rgb(211, 211, 211);
    font-size: 2rem;
}
.icon_downarrow span{
    font-size: 4.5rem;
}
.step3_etiquetas{
    flex-direction: column;
}
.step3_etiquetas_items{
    display: flex;
    flex-wrap:wrap;
    margin-top: 10px;
}
.step3_etiquetas_items .small{
    margin-bottom: 10px;
}
.btn_registro{
    width: 100%;
    margin: 1rem 0;
}
.popup_registroPlataforma p{
    color: white;
    margin-bottom: 20px;
    line-height: 22px;
}
.popup_registroPlataforma span{
    margin-bottom: 35px;
    font-size: 16px;
    line-height: 22px;
}
.popup_registroPlataforma .btn.transparente{
    width: 100%;
    padding: 15px;
}
@media (max-width: 600px) {
    .modalBody .modalRegistro_cerrar{
        top:10px;
        right: 30px;
    }
    .step3{
        overflow:visible
    }
    .modalBody.modalRegistro {
        overflow: inherit;
        padding: 20px 0 20px 20px;
    }
  }
  @media (max-width: 400px) {
    .baseModal .modalRegistro{
        position: fixed;
        top:100px;      
        margin: 0;
        width: calc(100vw-30px);
        max-width: 100%;
        max-height: fit-content;
        height: 100vh;
        border: none;
        padding: 4rem 2rem;
    }
    .modalLogin_logo {
        flex-direction: column;
    }
  }

  .mt-10{
      margin-top: 10px;
  }
  .mb-10{
      margin-bottom: 10px;
  }