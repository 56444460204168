.selector{
    display: flex;
    margin-bottom: 1rem;
}
.selectorInput {
    position: relative;
    width: 100%;
    margin-right: 0%;
  }
  .selectorInputError {
    border: #DB4C4C solid 2px;
  }
  .selectorInput .selectorInputBtn {
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 60px;
    background: #fff;
    border-radius: 0;
    width: 100%;
    margin: 10px 0;
    color: #777;
    font-size: 16px;
    font-weight: 500;
    padding: 0 50px 0 15px;
    margin-right: 3%;
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }
  .selectorInput .selectorInputBtn.toggle {
    border-radius: 3px 3px 0 0;
  }
  .selectorInput .selectorInputDropdown {
    position: absolute;
    top: 100%;
    width: 100%;
    border-radius: 0 0 3px 3px;
    overflow: hidden;
    z-index: 1;
    background: #707070;
    -webkit-transform: scale(1, 0);
            transform: scale(1, 0);
    -webkit-transform-origin: top center;
            transform-origin: top center;
    visibility: hidden;
    transition: 0.2s ease;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    margin-top: -0.2rem;
  }
  .selectorInput .selectorInputDropdown .selectorInputOption {
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    cursor: pointer;
  }
  .selectorInput .selectorInputDropdown .selectorInputOption:hover {
    background: black;
  }
  .selectorInput .selectorInputDropdown.toggle {
    visibility: visible;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  @media (max-width: 600px) {
    .selectorInput .selectorInputBtn{
        font-size: 16px;
    }
  }