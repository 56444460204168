/* RESET  */
*{padding:0;margin:0;box-sizing:border-box;}
ol, ul, li {text-decoration:inherit;}
a{color:inherit; text-decoration:inherit;}
table {	border-collapse: collapse;border-spacing: 0;}
img{width:100%; height:auto; vertical-align: bottom;}
button{border:none;}
b{font-weight:600 ;}
.html-body_content ul {
  margin-left: 20px;
  margin-bottom: 20px;
}


/*
@media (min-width: 576px) { }

@media (min-width: 768px) { }

@media (min-width: 992px) { }

@media (min-width: 1200px) { }

@media (min-width: 1400px) { }
*/


input[type="file"] {
  color: transparent;
}

a{
  color: #999999;
  text-decoration: none;
}

a:hover, a.activo{
  color: #fff;
}
.mensajeError{
  color: #DB4C4C;
  width: 100%;
  font-size: 12px;
}
.mensajeError2{
  color: #DB4C4C;
  width: 100%;
  font-size: 12px;
  margin-top: -5px;
}
input.hasError{
  border: #DB4C4C solid 2px;
}
input.hasErrorCheck{
  box-shadow: 0px 0px 0px 1px rgba(255,0,0,.8);
}
.content-labels{
  display: flex ;
  flex-wrap: wrap;
}
.checktxt{
  opacity: 0;
}

.labelckeck{
  color: #000;
  background-color: #fff;
  border:#fff solid 1px;
  border-radius: 3px;
  padding: 5px; 
  margin: 5px;
  cursor: pointer;
}

.labelckeck.selected {
  border: #BBF1A0 solid 1px;
  color: #BBF1A0;
}

body {
  margin: 0;
  font-family:  'Lato', sans-serif;
  background-color: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 90%, rgba(249,246,241,1) 100%);
  color: #999999;
  font-size: 18px;
  font-weight: 500;
  
}

.section{
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
}

.section.bg-none{
  width: 100%;
  padding-top: 0px;
  padding-bottom: 0px;
}

.section:nth-child(2n) {
  /* background: #f5f5f5!important; */
}

.container{
  margin-right: auto;
  margin-left: auto;
  max-width: 100%;
}

@media (min-width: 576px) { 
  .container{
    max-width: 540px;
  }
}

@media (min-width: 768px) { 
  .container{
    max-width: 720px;
  }
}

@media (min-width: 992px) { 
  .container{
    max-width: 960px;
  }
}

@media (min-width: 1200px) { 
  .container{
    max-width: 1140px;
  }
}

@media (min-width: 1400px) { 
  .container{
    max-width: 1320px;
  }
}

@media (min-width: 1700px) { 
  .container{
    max-width: 1680px;
  }
}

.container.txt-center {
  text-align: center;
}

.container.mb-60 {
  margin-bottom: 60px;
}

@media (max-width: 768px) { 
  .container.mb-60.txt-center{
    padding: 0 20px;
    margin-bottom: 30px;
  }
}


.row{
  display: flex;
  flex-wrap: wrap;
}

.col-12{
  width: 100%;
}

.col-2{
  width: 100%;
}
.col-3{
  width: 100%;
}
.col-4{
  width: 100%;
}
.col-6{
  width: 100%;
}


@media (min-width: 576px) { 
  .col-2{
    width: 100%;
  }
  .col-3{
    width: 100%;
  }
  .col-4{
    width: 100%;
  }
  .col-6{
    width: 100%;
  }
}

@media (min-width: 768px) { 
  .col-2{
    width: 50%;
  }
  .col-3{
    width: 50%;
  }
  .col-4{
    width: 50%;
  }
  .col-6{
    width: 50%;
  }
}

@media (min-width: 992px) { 
  .col-2{
    width: 50%;
  }
  .col-3{
    width: 25%;
  }
  .col-4{
    width: 33%;
  }
  .col-6{
    width: 50%;
  }
}

@media (min-width: 1200px) { 
  .col-2{
    width: 50%;
  }
  .col-3{
    width: 25%;
  }
  .col-4{
    width: 33%;
  }
  .col-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 74.666667%;
    flex: 0 0 74.666667%;
    max-width: 74.666667%;
  }
  .col-6{
    width: 50%;
  }
  
}

@media (min-width: 1400px) { 
  .col-2{
    width: 50%;
  }
  .col-3{
    width: 25%;
  }
  .col-4{
    width: 33%;
  }
  .col-6{
    width: 50%;
  }
}

b{
  color: #000;
}

.form-group.col-2{
  width: 100%;
}


.form-group.col-2.horizontal {
    display: flex;
    margin: 0;
}

.form-group .title-field {
  font-weight: 600;
  margin-right: 30px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.form-group .title-field a{
  color: #3B619E;
  text-decoration: underline;
}

.form-group.col-2.horizontal .title-field {
  font-weight: 600;
  margin-right: 30px;
  margin-bottom: 20px;
  margin-top: 0px;
}

.form-group .inputGroup{

  margin-right: 20px;
}


.form-group.col-2.horizontal {
  display: flex;
  margin: 20px 0 0 0;
}

.info-form {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 30px;
}

.info-form hr {
  margin-bottom: 30px;
}

.content-info-form {
  font-size: 11px;
}

.titulo-info-form {
  margin-bottom: 15px;
}

.slick-next, .slick-prev {
  font-size: 0;
  line-height: 0;
  top: 50%;
  width: 34px;
  height: 34px;
  transform: translate(0,-50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: #ccc;
  z-index: 99;
  border-radius: 50px;
}

.evento_container .slick-list {
  overflow: hidden;
  margin: 0;
  padding: 0;
  padding: 30px;
  margin: 0 -30px;
}

.slick-next{
  right: -40px;
}
.slick-prev {
  left: -40px;
}
.slick-next:before, .slick-prev:before {
  font-size: 22px;
  line-height: 1;
  opacity: .75;
  color: #fff;
  font-family: FontAwesome;
}

.slick-next:before, [dir=rtl] .slick-prev:before {
  content: '\f054';
}

[dir=rtl]  .slick-next:before, .slick-prev:before {
  content: '\f053';
}

.slick-next:focus, .slick-next:hover, .slick-prev:focus, .slick-prev:hover {
  opacity: 1;
  color: #fff;
  outline: 0;
  background: #3b619e;
}
button.slick-arrow.slick-disabled {
  display: none!important;
}

.slider-principal .slick-arrow {
  display: none!important;
}
img.img-responsive {
  width: 100%;
  height: auto;
}
.destacado{
  color: #3B619E;
}
.margin-tb-30{
  margin: 30px 0;
}

.margin-30{
  margin: 30px;
}

.v-center{
  display: flex;
  align-items: center;
}

.bg-gradientDark{
  background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(249,246,241,1) 0%, rgba(249,246,241,1) 25%, rgba(255,255,255,1) 90%, rgba(249,246,241,1) 100%);
}
  

.bg-gradientDarkFlip{
  background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(249,246,241,1) 0%, rgba(249,246,241,1) 25%, rgba(255,255,255,1) 90%, rgba(249,246,241,1) 100%);
 
}

.bg-gradientFooter{
  background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 90%, rgba(249,246,241,1) 100%);
 
}

.cabecera-cuenta-usuario {
  padding: 80px 0;
  text-align: center;
}
.cabecera-cuenta-usuario .titulo{
  font-weight: 300;
  font-size: 47px;
  margin-bottom: 20px;
}
.cabecera-cuenta-usuario .titulo span{
  font-weight: 600;
  font-size: 47px;
}
.cabecera-cuenta-usuario .subtitulo{
  color:#999999;
  font-weight: 300;
  font-size: 28px;
}
.section-border-b{
  border-bottom: 1px solid #70707080;
}
.section-border-t{
  border-top: 1px solid #70707080;
}

.bg-dark{
  background: #040715;
}

.bg-black{
  background: #000;
}

.bg-white{
  background: #fff;
}


code {
  font-family: 'Lato', sans-serif;
}

.txt-centrado{
  text-align: center;
}
.titulo{
  color: #FFFFFF;
  font-size: 38px;
  line-height: 49px;
  font-weight: 600;
}
@media (max-width: 768px) { 
  .titulo{
    font-size: 28px;
    line-height: 32px;
  }
}
.cross{
  color: #50A4FB;
}
.subtitulo{
 color: #999999;
 font-size: 19px;
 line-height: 29px;
 font-weight: 600;
}
.caja-lateral.caja-stands .titulo{
  margin-bottom: 0;
}
.caja-lateral.caja-stands img{
  width: 100%;
  height: auto;
  margin-bottom: 20px;

}
.caja-lateral{
  background: #262632;
  box-shadow: 0px 0px 20px #00000065;
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 30px;
  font-size: 16px;
}
.caja-lateral {
  color: #999999;
  font-size: 16px;
}
.caja-lateral.widget_chat h3{
  color: white;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
}
.caja-lateral.widget_chat p{
  font-size: 17px;
  margin-bottom: 15px;
}
.caja-lateral.widget_chat .chat_button{
  background-color: #54A6F8;
  font-size: 20px;
  color: white;
  text-align: center;
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;
}
.caja-lateral.widget_chat .chat_button a{
  color: white;
}
@media (max-width: 1100px) {
  .caja-lateral.widget_chat{
    display: none;
  }
}
.evento_online_lateral_content .secundario {
  text-align: center;
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  background-color: rgba(185, 48, 48, 0);
  border: 1px solid white;
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-size: 18px;
  color: white;
  cursor: pointer;
}
.caja-lateral img {
  width: 100%;
  height: 130px;
  object-fit: contain;
}
.caja-lateral b{
  color: #FFFFFF;
}
.caja-lateral .titulo{
  font-size: 24px;
  margin-bottom: 20px;
}
.caja-lateral .subtitulo{
  font-size: 18px;
  margin-bottom: 20px;
}
.caja-lateral .btn{
  margin-top: 1rem;
}
.descargardocumentos_titulo{
  color: white;
  margin-bottom: 0.2rem;
}
.descargardocumentos_items a{
  font-size: 0.9rem;
}
@media (max-width: 500px) {
  .caja-lateral .titulo{
    font-size: 1.5rem;
  }
  .caja-lateral {
    font-size: 0.9rem;
  }
  .caja-lateral .btn{
    font-size: 0.9rem;
  }
  .caja-lateral label.small{
    font-size: 0.8rem;
  }
}


.caja-etiquetas {
  display: flex;
  margin: 10px 0;
  flex-wrap: wrap;
}

.caja-etiqueta{
  background: transparent;
  color: #CCCCCC;
  border: #CCCCCC solid 1px;
  font-size: 12px;
  margin-right: 10px;
  padding: 2px 8px;
  border-radius: 3px;
  margin-bottom: 10px;
}

.caja-etiqueta.dark{
  background: #333333;
  color: #999999;
}

.evento .row{
  margin: 0 -20px 0 -20px;
}

.evento .col-8, .evento .col-4{
  padding: 0 20px;
}


.container-img {
  position: relative;
  text-align: center;
  width: 100%;
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: stretch;
}



.container-img img{
    width: 100%;
    height: auto;
}
.container-img .centered {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .8);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 768px) { 
  .container-img {
    flex-direction: column;
  }
  /*.container-img .centered {
    position: relative;
  } */
}

.container-img .centered form label.small{
  color:#999;
}
.container-img .centered  .claimLogin{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.container-img .centered    .imgCandado img{
  width: 50px;
  height: auto;
  margin-bottom: 10px;
}
.container-img .centered   .btn {
  width: auto;
  margin: auto;
  padding: 10px 20px;
}





.video-js{
  width: 100%;
  height: 600px;
}

.caja-etiquetas-linea span{
  font-size: 17px;
  margin-right: 0.5rem;
}
.caja-etiquetas-linea span::after{
  content: ",";
}
.caja-etiquetas-linea span:last-of-type::after{
  content: ".";
}
.caja-etiquetas-linea{
  border-top: 1px solid #707070;
  border-bottom: 1px solid #707070;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 0;
  margin: 40px 0;
  margin-bottom: 70px;
  flex-wrap: wrap;
  font-weight: 400;
}
.titulo-caja-etiquetas-linea{
  color: #fff;
  font-size: 17px;
  padding: 0 5px 0 0;
  line-height: 1.8rem;
}

.item-caja-etiquetas-linea{
  color: #999;
  font-size: 19px;
  padding: 0 5px;
}

.cuenta-usuario_contentbox{
  display: grid;
  grid-template-columns: 30% auto;
  padding:5rem 200px;
}
@media (max-width: 1300px) {
  .cuenta-usuario_contentbox{
      padding: 5rem 100px;
  }
}
@media (max-width: 1200px) {
  .cuenta-usuario_contentbox{
    grid-template-columns: auto;
    padding: 5rem;
  }
}
@media (max-width: 500px) {
  .cuenta-usuario_contentbox{
    padding: 2rem;
  }
}


/* EVENTO ONLINE */
.evento_container{
  padding: 2rem 7%;
  display: grid;
  grid-template-columns: 100% ;
}

@media (min-width: 576px) {  }


@media (min-width: 768px) {  }


@media (min-width: 992px) {  }


@media (min-width: 1200px) {  }


@media (min-width: 1400px) { 
  .evento_container {
    padding: 0;
    display: grid;
    grid-template-columns: 100%;
    width: 1360px;
    margin: auto;
  }
  .evento_container .container-carrusel .titulo {
    font-size: 42px;
    line-height: 52px;
    font-weight: 300;
    text-align: left;
  }
  .cardEvento {
    position: relative;
    background: #F9F6F1;
    border-radius: 5px;
    box-shadow: 0px 0px 20px #00000065;
    text-align: left;
    overflow: hidden;
    width: 390px;
    height: 550px;
    margin-right: 1.5rem;
    box-shadow: 0 2px 2px rgb(0 0 0 / 16%);
    border: 1px solid rgba(0,0,0,.1);
    margin-bottom: 30px;
    transition: all .2s ease-in-out;
  }
}


.botones_registro_videoplayer{
  width: 50%;
}
.botones_registro_videoplayer_icon{
  align-self: center;
  margin-bottom: 2rem;
  color: #7C7C7C;
}
.botones_registro_videoplayer_icon svg {
  width: 44px;
  HEIGHT: 51px;
}
.botones_registro_videoplayer_icon svg path{
  stroke: #fff;
}
.botones_registro_videoplayer .svg-inline--fa.fa-w-20{
  width: 100px;
  height: auto;
}
.botones_registro_videoplayer .botones_registro_videoplayer_msg{
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  line-height: 2.2rem;
  color: #ddd;
  font-weight: 300;
  max-width: 450px;
}
.botones_registro_videoplayer .botones_registro_videoplayer_msg span{
  color: white;
  font-weight: 600;
}
.botones_registro_videoplayer img{
  width: 22px;
  height: 22px;
  align-self: center;
  margin-bottom: 1rem;
}
.botones_registro_videoplayer .boton_registro_videoplayer1{
  align-self: center;
  width: 60%;
  padding: 1.3rem 2rem;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, .5);
  border: 1px solid white;
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  cursor: pointer;
}
.botones_registro_videoplayer .boton_registro_videoplayer1:hover{
  opacity: .9;
}
.botones_registro_videoplayer .boton_registro_videoplayer2{
  align-self: center;
  width: 60%;
  padding: 1.3rem 2rem;
  border-radius: 5px;
  background: #fff;
  text-transform: uppercase;
  font-size: 1.2rem;
  cursor: pointer;
  margin-bottom: 20px;
  color: #666;
}
.botones_registro_videoplayer .boton_registro_videoplayer2:hover{
  opacity: .9;
}
.evento-etiqueta{
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
}
.evento-labels{
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
}
.evento-labels span{
  font-size: .85rem;
  font-weight: 400;
  padding: .2rem .7rem;
  border-radius: 3px;
  margin-right: .6rem;
  letter-spacing: 0.36px;
}
.evento-labels .label_canal{
  background-color: #ccc;
  color: #000;
}
.evento-labels .label_tipo{
  background-color: #333333;
    color: #999999;
}
.evento-labels .label_categoria{
  background-color: black;
  color:white;
}
.evento-labels .label_categoria span{
  padding: 0;
  margin: 0;
}
.botones_registro_lateral1{
  text-align: center;
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid white;
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-size: 18px;
  color: white;
  cursor: pointer;
}
.botones_registro_lateral2{
  text-align: center;
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  background: linear-gradient(90deg, #50A4FB, #00E2FA);
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-size: 18px;
  color: white;
  cursor: pointer;
}
.evento-etiqueta span{
  font-size: .85rem;
  font-weight: 400;
  padding: .2rem .7rem;
  background-color: white;
  color:black;
  border-radius: 3px;
  margin-right: .6rem;
  letter-spacing: 0.36px;
}
.evento_online_main_content{
  margin-right: 0px;
}
.evento_online_main_content_img{
  width: 100%;
  margin-bottom: 70px;
}
@media (max-width: 500px) {
  .evento_online_main_content_img{
    width: 100%;
    margin-bottom: 30px;
  }
}
.evento_online_main_content h2{
  font-size: 42px;
  color: #111;
  margin-bottom: 30px;
  font-weight: 300;
}
.evento-fecha .titulo{
  font-size: 21px;
  color: #3C3C3C;
  line-height: 2.9rem;
  margin-bottom: 20px;
  font-weight: 500;
}
.evento_online_main_content .evento-ponente .titulo{
  color: #3B619E;
  font-weight: 400;
  font-size: 29px;
}

.evento_online_main_content .evento-ponente .subtitulo{
  color: #666;
  font-weight: 400;
  font-size: 22px;
}

.evento_online_main_content .evento-fecha .subtitulo{
  font-size: 28px;
  color:#3B619E;
  font-weight: 500;
}
.evento_online_main_content p{
  font-size: 19px;
  color: #3C3C3C;
  margin-bottom: 29px;
  font-weight: 400;
}
.evento_online_main_content .subtitulo{
  font-size: 1rem;
  margin-bottom: 2rem;
}
.evento_online_main_content b{
  font-size: 19px;
  color:white;
  display: block;
}
.evento_online_main_content ul{
  margin-bottom:18px;
  margin-left: 18px;
}
.evento_online_main_content li{
  font-size: 19px;
  margin-left: -10px;
}
.evento_online_main_content b{
  display: inline;
}
.evento_online_main_content li::before {
  content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #3B619E;; /* Change the color */
  font-weight: 700; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 10px; /* Also needed for space (tweak if needed) */
  margin-left: -10px; /* Also needed for space (tweak if needed) */
  padding: 0.5rem .5rem 0 0;
}
.evento_online_main_content .registro-presencial{
  max-width: 315px;
  margin-bottom: 70px;
  text-align: left;
}

.agenda-evento{
  margin-bottom: 2rem;
}
.agenda-evento h2{
  font-size: 38px;
  margin-bottom: 29px;
  font-weight: 600;
}
.item-agenda > div:first-of-type{
  display:flex;
}

.item-agenda > .container-carrusel{
  margin: 5rem 4rem 4rem;
  display: contents!important;
}
.item-agenda > .container-carrusel .titulo{
  margin-top: 50px;
}
.item-agenda .container-carrusel .cardEvento{
  background: #2F2F40;
}
.item-agenda:nth-of-type(odd) .item-agenda-fecha{
  background: rgba(51,51,62,.7);
}
.item-agenda:nth-of-type(even) .item-agenda-fecha{
  background: rgba(47,47,64,.7);
}

.item-agenda:nth-of-type(odd) .item-agenda-descripcion{
  background: rgba(51,51,62,.9);
}
.item-agenda:nth-of-type(even) .item-agenda-descripcion{
  background: rgba(47,47,64,.9);
}
.item-agenda-fecha {
  display: flex;
  flex-direction: column;
  width: 275px;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}
.item-agenda-descripcion {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 35px;
  flex: 1;
  width: 100%;
}
.item-agenda-fecha-titulo {
  font-size: 18px;
  color: #3B619E;
  margin-bottom: 10px;
  padding: 0 10px;
  text-align: center;
}
.item-agenda-fecha-hora {
  color: #fff;
  font-size: 18px;
}
.item-agenda-desc-titulo {
  color: #fff;
  font-size: 22px;
  margin-bottom: 10px;
}
.item-agenda li{
  font-size: 18px;
}
.item-agenda p{
  font-size: 18px;
}
.item-agenda li::before {
  content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #7A7A7A;; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 10px; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}
.item-agenda-desc-ponete {
  font-size: 18px;
  color: #999;
}
.item-agenda-desc-contenido {
  color: #999;
  font-size: 18px;
}
@media (max-width: 850px) {
  .evento-etiqueta{
    margin-top: 45px;
  }
  .item-agenda div:first-of-type{
    display:flex;
    flex-direction: column;
  }
  .item-agenda-fecha {
    padding: 20px 15px;
    width: 100%;
  }
  .item-agenda-fecha-titulo{
    font-size: 0.9rem;
    margin-bottom: 0;
  }
  .item-agenda-fecha-hora {
    font-size: 0.9rem;
  }
  .item-agenda-desc-titulo {
    font-size: 0.9rem;
  }
  .item-agenda li{
    font-size: 0.9rem;
  }
  .item-agenda p{
    font-size: 0.9rem;
  }
  .item-agenda-desc-ponete {
    font-size: 0.9rem;
  }
}

.section_ponentes{
  padding: 0 7%;
}
.section_sponsors{
  padding: 2rem 7%;
}
.cajaSponsors{
  margin-bottom: 1rem;
}
.cajaSponsors .tituloSponsor{
  color: #999999;
  width: 100%;
  font-size: 18px;
  border-bottom: solid 1px #999;
  margin-bottom: 15px;
}
.cajaSponsors.destacado .tituloSponsor{
  font-size: 28px;
}
.cajaSponsors .gridsponsors{
   display: grid;
   grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
   grid-gap: 20px;
}
.cajaSponsors.destacado  .gridsponsors{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 40px;
}
.cajaSponsors .itemSponsor img{
  width: 100%;
  max-width: 156px;
}
.cajaSponsors.destacado .itemSponsor img{
  width: 100%;
  max-width: 278px;
}
.container_ponentes{
  padding: 0 2rem;
}
.container_ponentes .grid-item{
  margin-bottom: 1rem;
}
.evento_online_lateral_content .secundario{
  margin-top: 20px;
  width: 100%;
}
@media (max-width: 1400px) {
  .botones_registro_videoplayer{
    width: 90%;
  }
  .botones_registro_videoplayer_icon{
    margin-bottom: 0.5rem;
  }
}
@media (max-width: 1100px) {
  .botones_registro_videoplayer .boton_registro_videoplayer1{
    padding: 1rem 1rem;
  }
  .botones_registro_videoplayer .boton_registro_videoplayer2{
    padding: 1rem 1rem;
  }
  .evento_container{
    grid-template-columns: auto;
    padding: 2rem;
  }
  .evento_online_main_content{
    margin-right: 0;
  }
  .botones_registro_videoplayer_icon{
    margin-bottom: 2rem;
  }
}
@media (max-width: 800px) {
  .botones_registro_videoplayer{
    width: 90%;
  }
  .evento_container{
    display: block;
  }
  .botones_registro_videoplayer_icon{
    width: 50px;
    margin-bottom: 1rem;
  }
  .botones_registro_videoplayer .svg-inline--fa.fa-w-20{
    width: 50px;
  }
}
@media (max-width: 650px) {
  .botones_registro_videoplayer .botones_registro_videoplayer_msg{
    font-size: 0.9rem;
    line-height: 1.2rem;
  }
  .botones_registro_videoplayer_icon{
    width: 35px;
    margin-bottom: 0.4rem;
  }
  .botones_registro_videoplayer .svg-inline--fa.fa-w-20{
    width: 35px;
  }
  .botones_registro_videoplayer .boton_registro_videoplayer1{
    font-size: 0.8rem;
    padding: 0.6rem;
    margin-bottom: 0.4rem;
  }
  .botones_registro_videoplayer .boton_registro_videoplayer2{
    font-size: 0.8rem;
    padding: 0.6rem;
  }
}
@media (max-width: 500px) {
  .evento_container{
    padding: 1rem;
  }
  .evento-etiqueta{
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .evento_online_main_content .evento-fecha .subtitulo{
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
  .evento_online_main_content h2{
    font-size: 1.2rem;
    margin-bottom: 29px;
  }
  .container_ponentes .titulo {
    font-size: 1.5rem;
  }
  .evento_online_main_content .titulo{
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    line-height: 1.5rem;
  }
  .evento_online_main_content .subtitulo{
    font-size: 0.9rem;
    margin-bottom: 25px;
  }
  .evento_online_main_content p{
    font-size: 0.9rem;
  }
  .evento_online_main_content li{
    font-size: 0.9rem;
  }
  .evento_online_main_content b{
    font-size: 1.1rem;
  }
  .botones_registro_videoplayer_icon{
    width: 30px;
    margin-bottom: 0.4rem;
  }
  
  .botones_registro_videoplayer .svg-inline--fa.fa-w-20{
    width: 30px; 
  }
  .botones_registro_videoplayer .botones_registro_videoplayer_msg{
    font-size: 0.7rem;
    line-height: 1rem;
    margin-bottom: 0.2rem;
  }
  .botones_registro_videoplayer img{
    height: 15px;
    width: 15px;
    margin-bottom: .5rem;
  }
  .botones_registro_videoplayer .boton_registro_videoplayer1{
    font-size: 0.6rem;
    padding: 0.4rem;
  }
  .botones_registro_videoplayer .boton_registro_videoplayer2{
    font-size: 0.6rem;
    padding: 0.4rem;
  }
  .agenda-evento h2{
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  .titulo-caja-etiquetas-linea{
    font-size: 0.9rem;
  }  
  .item-caja-etiquetas-linea{
    font-size: 0.9rem;
  }
  .caja-etiquetas-linea span{
    font-size: 0.9rem;
  }
  .cajaSponsors.destacado .tituloSponsor{
    font-size: 1.5rem;
  }
}



.homepublica_contenidos_todo{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin:70px 50px;
}
.homepublica_contenidos_cabecera{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 70px;
  text-align: center;
}
.homepublica_contenidos_cabecera h2{
  font-size: 38px;
  color: #FFFFFF;
  font-weight: 500;
  margin-bottom: 10px;
}
.homepublica_contenidos_cabecera p{
  font-size: 19px;
  color: #999999;
}
.homepublica_contenidos_grid{
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
}
.homepublica_contenidos_8grid{
  display: grid;
  grid-template-columns: auto auto auto auto;
  row-gap: 3rem;
  column-gap: 2rem;
}
.homepublica_contenidos_8grid .cardEvento{
  margin-right: 0;
}
.homepublica_repuesto{
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
}
.homepublica_repuesto_btn{
  text-align: center;
  width: 446px;
  background: linear-gradient(90deg, rgba(80,164,251,1) 0%, rgba(0,226,250,1) 100%);
  border: 1px solid #00E2FA;
  border-radius: 5px;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  padding: 20px;
  text-transform: uppercase;
  cursor: pointer;
}
@media (max-width: 1600px) {
  .homepublica_contenidos_8grid{
    grid-template-columns: auto auto auto;
    column-gap: 2rem;
    row-gap: 2rem;
  }
}
@media (max-width: 1200px) {
  .homepublica_contenidos_8grid{
    grid-template-columns: auto auto;
  }
}
@media (max-width: 800px) {
  .homepublica_contenidos_8grid{
    grid-template-columns: auto;
    row-gap: 2rem;
  }
  .homepublica_contenidos_cabecera h2{
    font-size: 27px;
  }
  .homepublica_contenidos_cabecera p{
    font-size: 14px;
  }
}
@media (max-width: 500px) {
  .homepublica_repuesto_btn{
    width: 250px;
    font-size: 15px;
    line-height: 20px;
  }
  .homepublica_contenidos_cabecera{
    margin-bottom: 70px;
  }
  .homepublica_contenidos_cabecera h2{
    font-size: 22px;
  }
  .homepublica_contenidos_cabecera p{
    font-size: 12px;
  }
}
.footBanners{
  display: grid;
  grid-template-columns: auto auto;
}
.footBanners_item1{
  position: relative;
  margin: 0.5rem;
}
.footBanners_item1_content{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  text-align: center;
}
.footBanners_item1_content img:first-of-type{
  width: 75px;
}
.footBanners_item1_content img{
  width: 450px;
  margin-bottom: 20px;
}
.footBanners_item1_content h3{
  color: white;
  font-size: 38px;
  font-weight: 400;
  margin-bottom: 20px;
}
.footBanners_item1_content p{
  font-size: 19px;
  margin-bottom: 20px;
}
.footBanners_btn{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #48A7F2;
  border-radius: 5px;
  width: 326px;
  height: 70px;
  color: white;
  font-weight: 400;
  font-size: 23px;
  cursor: pointer;
}
@media (max-width: 1250px) {
  .footBanners_item1_content img:first-of-type{
    width: 60px;
  }
  .footBanners_item1_content img{
    width: 250px;
  }
  .footBanners_item1_content h3{
    font-size: 30px;
  }
  .footBanners_item1_content p{
    font-size: 16px;
    margin-bottom: 50px;
  }
  .footBanners_btn{
    width: 250px;
    height: 60px;
    font-size: 20px;
  }
}
@media (max-width: 1100px) {
  .footBanners_item1_content img:first-of-type{
    width: 45px;
  }
  .footBanners_item1_content img{
    width: 210px;
  }
  .footBanners_item1_content h3{
    font-size: 26px;
  }
  .footBanners_item1_content p{
    font-size: 14px;
  }
}
@media (max-width: 960px) {
  .footBanners_item1_content img:first-of-type{
    margin-bottom: 10px;
  }
  .footBanners_item1_content img{
    margin-bottom: 10px;
  }
  .footBanners_item1_content h3{
    margin-bottom: 10px;
  }
  .footBanners_item1_content p{
    margin-bottom: 10px;
  }
  .footBanners_btn{
    width: 180px;
    height: 50px;
    font-size: 14px;
  }
}
@media (max-width: 800px) {
  .footBanners{
    grid-template-columns: auto;
  }
  .footBanners_item1_content img:first-of-type{
    width: 60px;
  }
  .footBanners_item1_content img{
    width: 450px;
    margin-bottom: 20px;
  }
  .footBanners_item1_content h3{
    font-size: 38px;
    margin-bottom: 20px;
  }
  .footBanners_item1_content p{
    font-size: 19px;
    margin-bottom: 20px;
  }
  .footBanners_btn{
    width: 250px;
    height: 60px;
    font-size: 20px;
  }
}
@media (max-width: 600px) {
  .footBanners_item1_content img:first-of-type{
    width: 60px;
  }
  .footBanners_item1_content img{
    width: 250px;
  }
  .footBanners_item1_content h3{
    font-size: 30px;
  }
  .footBanners_item1_content p{
    font-size: 16px;
    margin-bottom: 50px;
  }
}
@media (max-width: 550px) {
  .footBanners_item1_content img:first-of-type{
    margin-bottom: 10px;
    width: 45px;
  }
  .footBanners_item1_content img{
    margin-bottom: 10px;
    width: 210px;
  }
  .footBanners_item1_content h3{
    margin-bottom: 10px;
    font-size: 26px;
  }
  .footBanners_item1_content p{
    margin-bottom: 10px;
  }
  .footBanners_btn{
    width: 180px;
    height: 50px;
    font-size: 14px;
  }
}
@media (max-width: 450px) {
  .footBanners_item1_content img:first-of-type{
    margin-bottom: 5px;
    width: 40px;
  }
  .footBanners_item1_content img{
    margin-bottom: 5px;
    width: 180px;
  }
  .footBanners_item1_content h3{
    margin-bottom: 5px;
    font-size: 20px;
  }
  .footBanners_item1_content p{
    font-size: 12px;
    margin-bottom: 10px;
  }
  .footBanners_btn{
    width: 150px;
    height: 40px;
    font-size: 12px;
  }
}

.footBanners_item2{
  position: relative;
  margin: 0.5rem;
}
.footBanners_item2_content{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 50%;
  left: 5%;
  transform: translate(0%, -50%);
  width: 42%;
  text-align: left;
}
.footBanners_item2_content img:first-of-type{
  width: 75px;
}
.footBanners_item2_content img{
  width: 450px;
  margin-bottom: 20px;
}
.footBanners_item2_content h3{
  color: #54A6F8;
  font-size: 38px;
  font-weight: 400;
  margin-bottom: 20px;
}
.footBanners_item2_content p{
  font-size: 19px;
  margin-bottom: 20px;
}
@media (max-width: 1500px) {
  .footBanners_item2_content img{
    width: 350px;
  }
}
@media (max-width: 1250px) {
  .footBanners_item2_content img:first-of-type{
    width: 60px;
  }
  .footBanners_item2_content img{
    width: 250px;
  }
  .footBanners_item2_content h3{
    font-size: 30px;
  }
  .footBanners_item2_content p{
    font-size: 16px;
    margin-bottom: 50px;
  }
}
@media (max-width: 1100px) {
  .footBanners_item2_content img:first-of-type{
    width: 45px;
  }
  .footBanners_item2_content img{
    width: 210px;
  }
  .footBanners_item2_content h3{
    font-size: 26px;
  }
  .footBanners_item2_content p{
    font-size: 14px;
  }
}
@media (max-width: 960px) {
  .footBanners_item2_content{
    width: 50%;
  }
  .footBanners_item2_content img:first-of-type{
    margin-bottom: 10px;
  }
  .footBanners_item2_content img{
    margin-bottom: 10px;
  }
  .footBanners_item2_content h3{
    margin-bottom: 10px;
  }
  .footBanners_item2_content p{
    margin-bottom: 10px;
  }
}
@media (max-width: 800px) {
  .footBanners_item2_content{
    width: 45%;
  }
  .footBanners_item2_content img:first-of-type{
    width: 60px;
  }
  .footBanners_item2_content img{
    width: 350px;
    margin-bottom: 20px;
  }
  .footBanners_item2_content h3{
    font-size: 38px;
    margin-bottom: 20px;
  }
  .footBanners_item2_content p{
    font-size: 19px;
    margin-bottom: 20px;
  }
}
@media (max-width: 650px) {
  .footBanners_item2_content img:first-of-type{
    width: 60px;
  }
  .footBanners_item2_content img{
    width: 250px;
  }
  .footBanners_item2_content h3{
    font-size: 30px;
  }
  .footBanners_item2_content p{
    font-size: 16px;
    margin-bottom: 50px;
  }
}
@media (max-width: 550px) {
  .footBanners_item2_content{
    width: 45%;
  }
  .footBanners_item2_content img:first-of-type{
    margin-bottom: 10px;
    width: 45px;
  }
  .footBanners_item2_content img{
    margin-bottom: 10px;
    width: 210px;
  }
  .footBanners_item2_content h3{
    margin-bottom: 10px;
    font-size: 26px;
  }
  .footBanners_item2_content p{
    margin-bottom: 10px;
  }
}
@media (max-width: 450px) {
  .footBanners_item2_content img:first-of-type{
    margin-bottom: 5px;
    width: 40px;
  }
  .footBanners_item2_content img{
    margin-bottom: 5px;
    width: 160px;
  }
  .footBanners_item2_content h3{
    margin-bottom: 5px;
    font-size: 20px;
  }
  .footBanners_item2_content p{
    font-size: 12px;
    margin-bottom: 10px;
  }
}



@media (max-width: 1200px) {
  .cajaSponsors .gridsponsors{
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
 }
 .cajaSponsors.destacado  .gridsponsors{
   grid-template-columns: 1fr 1fr 1fr 1fr;
 }
}
@media (max-width: 999px) {
  .cajaSponsors .gridsponsors{
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
 }
 .cajaSponsors.destacado  .gridsponsors{
   grid-template-columns: 1fr 1fr 1fr 1fr;
 }
  .homepublica_iconrow{
    display: grid;
    grid-template-columns: auto auto;
  }
}
@media (max-width: 700px) {
  .cajaSponsors .gridsponsors{
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
 }
 .cajaSponsors.destacado  .gridsponsors{
   grid-template-columns: 1fr 1fr 1fr;
 }
  .homepublica_contenidos .titulo{
    font-size: 2rem;
    padding: 0 1rem;
  }
  .homepublica_contenidos .subtitulo{
    font-size: 1rem;
    padding: 0 1rem;
  }
}
@media (max-width: 500px) {
  .cajaSponsors .gridsponsors{
    grid-template-columns: 1fr 1fr 1fr;
 }
 .cajaSponsors.destacado  .gridsponsors{
   grid-template-columns: 1fr 1fr;
 }
  .homepublica_iconrow{
    grid-template-columns: auto;
  }
  .homepublica_contenidos .titulo{
    font-size: 1.5rem;
    line-height: 1.4rem;
    margin-bottom: 0.5rem;
  }
  .homepublica_contenidos .subtitulo{
    font-size: 0.9rem;
    line-height: 1.3rem;
  }
}
@media (max-width: 400px) {
  .cajaSponsors .gridsponsors{
    grid-template-columns: 1fr 1fr;
 }
 .cajaSponsors.destacado  .gridsponsors{
   grid-template-columns: 1fr;
 }
}

@media (min-width: 576px){
  .container, .container-sm {
      max-width: 540px;
  }
}
@media (min-width: 768px){
  .container, .container-md, .container-sm {
      max-width: 720px;
  }
}
@media (min-width: 992px){
  .container, .container-lg, .container-md, .container-sm {
      max-width: 960px;
  }
}
@media (min-width: 1200px){
  .container, .container-lg, .container-md, .container-sm, .container-xl {
      max-width: 90%;
  }
}
@media (min-width: 1700px){
  .container, .container-lg, .container-md, .container-sm, .container-xl {
      max-width: 1655px;
  }
}

@media (min-width: 1800px){
  .container.principal {
      max-width: 1770px;
  }
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}