.html-body{
    min-height: 100vh;
    background: #F9F6F1;
    color: #3C3C3C;
}
.html-body h1{
    margin-top:0;
    margin-top: 0;
    color: #3B619E;
    font-weight: 300;
    font-size: 52px;
    margin-bottom: 30px;
}
.html-body_content{
    padding:5% 15%;
}
.html-body_content img, .html-body_content svg{
    width: 13rem;
}
.html-body_content h2, h3{
    color:#3C3C3C;
    font-size: 21px;
    font-weight:600;
    margin-bottom: 20px;
}
.html-body_content_section{
    line-height: 2rem;
}
.html-body_content h3{
    color:#3C3C3C;
    font-size: 21px;
    font-weight:600;
    margin-bottom: 20px;
}
.html-body_content p{
    font-size: 19px;
    margin-bottom: 19px;
  
}
@media (max-width: 500px) {
    .html-body_content{
        padding:5% 10%;
    }
    .html-body_content_section h3{
        margin-bottom: 1rem;
    }
    .html-body_content_section p{
        margin-bottom: 1rem;
    }
}
@media (max-width: 400px) {
    .html-body_content{
        padding:5% 7%;
    }
}