.contenido-cuenta {
    margin-top: 30px;
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: 180px;
}

.contenido-cuenta .titulo {
    font-size: 28px;
    margin-bottom: 40px;
}

.caja-item-cuenta{
    margin-top: 30px;
    text-align: center;
}

img.imagen-cuenta {
    border-radius: 50%;
}

.btn-cambiar-imagen {
    margin-top: 10px;
    text-align: center;
    cursor: pointer;
    color: #fff;
}
.DatosProfesionales_btn{
    margin-top: 30px;
    width: 100%;
    background: rgb(80,164,251);
    border: 1px solid #00E2FA;
    border-radius: 5px;
    display: inline-block;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    line-height: 27px;
    padding: 10px 50px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    font-family: 'Lato', sans-serif;
}
.formulario-cuenta .form-group{
    margin-bottom: 30px;
}
.formulario-cuenta .form-group .inputText{
    margin-bottom: 6px;
    font-size: 21px;
}
.formulario-cuenta .btn.formulario{
    padding: 20px 50px;
    border: none;
}
@media (max-width: 600px) {
    .formulario-cuenta .form-group .inputText{
        font-size: 16px;
    }
  }