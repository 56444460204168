.subirimagen{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2.5rem;
}
.subirimagen img{
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 50%;
}
.subirimagen_buttons{
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.subirimagen_buttons_select{
    width: 300px;
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;
}
.subirimagen_buttons_select p{
    margin: 0;
    color: white;
    font-weight: 400;
    min-width: 4rem;
    margin-bottom: 0.5rem;
    text-align: center;
}
.subirimagen_buttons_btn input{
    width: 300px;
    color: #999999;
    background-color: transparent;
    border:1px solid #999999;
    border-radius: 5px;
    padding: 15px;
    font-family: 'Lato', sans-serif;
    cursor: pointer;
    font-size: 18px;
    font-weight:500;
}
.subirimagen_buttons_btn .mensajeError{
    margin-top: 5px;
}
.subirimagen_buttons_select input::-webkit-file-upload-button{  
    width: 300px;
    color: #999999;
    background-color: transparent;
    border:1px solid #999999;
    border-radius: 5px;
    padding: 15px;
    font-family: 'Lato', sans-serif;
    cursor: pointer;
    font-size: 18px;
    font-weight:500;
    margin-bottom: 5px;
}
.mensajeError.verde{
    color: green;
}
@media (max-width: 500px) {
    .subirimagen{
        margin-bottom:40px;
    }
    .subirimagen img{
        width: 200px;
        height: 200px;
    }
    .subirimagen_buttons_btn input{
        width: 250px;
    }
    .subirimagen_buttons_select input::-webkit-file-upload-button{  
        width: 250px;
    }
    .subirimagen_buttons_select{
        width: 250px;
    }
  }