.baseModal {
    width: 100%;
    height: 100vh;
    background-color: rgba(11, 13, 26, .80);
    position: fixed;
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.baseModal.open{
    display: flex;
}

.modalBody.modalLogin {
    position: relative;
    max-width: 800px;
    max-height: 80vh;
    padding: 40px 50px;
    background-color: #F9F6F1;
    border: 1px solid #F9F6F1;
    border-radius: 0px;
    box-sizing: border-box;
    margin: 0 15px;
    text-align: left;
}
.modalBody.modalLogin .modalLogin_cerrar {
    position: absolute;
    top: -35px;
    right: 0;
    font-size: 24px;
    text-transform: capitalize;
    cursor: pointer;
    color: #EFEFEF;
    font-weight: 100;
}

.modalBody.modalLogin .modalLogin_cerrar span{
    font-weight: 400;
}

.modalBody.modalLogin .boton_iniciarsesion {
    background-color: #3B619E;
    width: auto;
    border-radius: 5px;
    border: none;
    padding: 20px 34px;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 22px;
    color: #fff;
    margin-top: 30px;
}

.modalBody .modalLogin_cerrar span{
    font-weight: 400;
}

.modalBody .titulo {
    font-size: 44px;
    line-height: 46px;
    margin-bottom: 35px;
    font-weight: 300;
    color: #3C3C3C;
}

.modalBody .titulo span{
    color: #BC122A;
}

.modalBody .tituloheader{
    text-align: center;
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 35px;
}
.modalLogin_logo {
    width: 100%;
    margin: auto;
    margin-bottom: 30px;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.modalLogin_logo img {
    width: auto;
    height: auto;
    vertical-align: bottom;
    max-width: 30%;
}

.modalLogin_logo img.logo{
    width: 363px;
    min-width: 363px;
}

.modalLogin_logo img.logodiptucion{
    width: 389px;
    min-width: 389px;
    height: 98px;
}

.modalLogin_logo img.logoagnda{
    width: 212px;
    min-width: 212px;
    margin-left: 30px;
    margin-top: 30px;
    height: 98px;
}

.modalBody .logosflex{
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.modalBody.modalLogin .inputText {
    height: 60px;
    background-color: #fff!important;
    background:  #fff!important;;
    border: #CCCCCC solid 1px;
    margin: 10px 0;
    font-size: 16px;
    padding: 0 15px;
    width: 100%;
    border-radius: 0;
}

.modalBody.modalLogin .form-group{
    margin-bottom: 15px;
}
.modalBody.modalLogin .form-group:nth-of-type(3){
    margin-bottom: 0px;
}
.recuerdame{
    margin-bottom: 15px;
}
.recuerdame input{
    margin-right: 5px;
}
.modalBody .labelInput{
    color: #DDDDDD ;
    font-weight: 500;
    margin-bottom: 15px;
    font-size: 18px;
}
.AVAST_PAM_loginform label{
    color: white;
    font-size: 18px;
}

.modalBody.modalLogin .helperLogin{
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    color: #3C3C3C;
}

.modalBody.modalLogin .helperLogin .destacado{
    color: #BC122A;
}

.modalBody.modalLogin .helperLogin .small{
    cursor: pointer;
    font-size: 18px;
}
.modalBody .texto_popup{
    color:#DDDDDD;
    font-size: 18px;
    margin-bottom: 30px;
}
.btn_popup{
    width: 100%;
    padding: 20px;
}
.modalBody .titulo_popup_pass{
    font-size: 28px;
}
.modalBody .helperLogin{
    margin-top: 30px;
}


.small{
    font-size: 12px;
}

label.small{
    font-size: 14px;
}

.inputText {
    height: 60px;
    background-color: #fff!important;
    background:  #fff!important;;
    border: #CCCCCC solid 1px;
    margin: 10px 0;
    font-size: 16px;
    padding: 0 15px;
    width: 100%;
    border-radius: 0;
}

.labelInput{
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    color: #FFF;
}
.boton_iniciarsesion{
    background-color: #bbf1a0;
    width: 100%;
    border-radius: 5px;
    border: none;
    padding: 1.3rem 0;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1rem;
}
/* width */
.modalBody::-webkit-scrollbar {
    width: 10px;
}
/* Track */
.modalBody::-webkit-scrollbar-track {
    background: #f1f1f1; 
}
/* Handle */
.modalBody::-webkit-scrollbar-thumb {
    background: #888; 
}
/* Handle on hover */
.modalBody::-webkit-scrollbar-thumb:hover {
    background: #555; 
}
.modalBody .btn.principal{
    width: 100%;
}
@media (max-width: 600px) {
    .baseModal {
        align-items:unset;
        padding-top: 10%;
    }
    .modalLogin.modalBody{
      
        padding: 40px 30px;
        overflow: inherit;
        
    }
    .baseModal .modalLogin .modalLogin_cerrar{
        top: -40px;
        right: 0;
    }
    .modalBody .tituloheader{
        font-size: 25px;
        margin-bottom: 10px;
    }
    .modalBody .titulo{
        font-size: 22px;
        line-height: 1.5rem;
    }
    .modalBody .labelInput{
        font-size: 14px;
    }
    .modalLogin_logo {
        flex-direction: column;
    }
    .modalLogin_logo img.logoagnda{
        width: 196px;
        min-width: 196px;
        margin-left: 0px;
        margin-top: 30px;
    }
    .modalLogin_logo img.logo {
        width: 100%;
        min-width: 100%;
    }
    .modalLogin_logo img.logodiptucion {
        width: 100%;
        min-width: 100%;
        margin-top: 20px;
    }
    .modalBody .logosflex {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
    }
  }
  @media (max-width: 400px) {
    .baseModal .modalLogin{
        position: fixed;
        top:100px;      
        margin: 0;
        width: calc(100vw-30px);
        max-width: 100%;
        max-height: fit-content;
        height: 100vh;
        border: none;
        padding: 3rem 2rem;
    }
  }