.btn {
  background: #3B619E;
  border: 1px solid #3B619E;
  border-radius: 10px;
  display: inline-block;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  padding: 10px 50px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}

.btn.principal {
  background: #3B619E;
  border: 1px solid #3B619E;
}

.btn.principal a{
  color: #fff!important;
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
}

.btn.secundario {
  background: #000;
  border: 1px solid #FFFFFF;
}

.btn.transparente {
  background: transparent;
  border: 1px solid #FFFFFF;
}

.btn.principal:hover {
  background: #fff;
  color: #3B619E;
  border: 1px solid #3B619E;
}

.btn.principal:hover a{
  color: #3B619E!important;
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
}
