.bannerPrincipal {
  position: relative;
}
.bannerPrincipal_background img{
  height: 565px; /*570px;*/
  object-fit: cover;
  vertical-align: bottom;
}
.banner-interior.bannerPrincipal .bannerPrincipal_background img{
  height: 565px;
  object-fit: cover;
  vertical-align: bottom;
}
.bannerInterior .bannerPrincipal_background img{
  height: 565px;
  object-fit: cover;
  vertical-align: bottom;
}
.bannerPrincipal_content {
  position: absolute;
  top: 100px;
  left: 150px;
  width: 600px;
}
.banner-interior.bannerPrincipal.bannerPrincipal_content {
  top: 50px;
}

.bannerInterior .bannerPrincipal_content {
  top: 50px;
}

.bannerPrincipal_content img{
  width: 325px;
  margin-bottom: 50px;
}
.bannerPrincipal_content .titulo{
  color: #FFFFFF;
  font-size: 38px;
  font-weight: 600;
  line-height: 49px;
  margin-bottom: 50px;
}
.bannerPrincipal_content .subtitulo{
    color: #999999;
    font-size: 19px;
    line-height: 29px;
    font-weight: 600;
    margin-bottom: 50px;
}
.bannerPrincipal_btn{
  width: 446px;
  background: linear-gradient(90deg, rgba(80,164,251,1) 0%, rgba(0,226,250,1) 100%);
  border: 1px solid #00E2FA;
  border-radius: 5px;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  padding: 10px 50px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}
@media (max-width: 1200px) {
  .bannerPrincipal_background img{
    object-position: 80%;
  }
}
@media (max-width: 992px) {
  .bannerPrincipal_content {
    top: 10%;
    left: 10%;
    width: 80%;
    background: rgb(0,4,9);
    background: linear-gradient(0deg, rgba(0,4,9,1) 0%, rgba(0,7,9,0.8491771708683473) 65%, rgba(0,8,9,0.6671043417366946) 87%, rgba(0,8,9,0) 100%);
    z-index: 99;
    position: relative;
    left: 0;
    width: 100%;
    padding: 5%;
    margin-top: -400px;
    padding-bottom: 40px;
  }
  .slider-principal .slick-dots {
    bottom: 0px!important;
    width: 100%;
    margin: 0;
    list-style: none;
    text-align: center;
  }
  .banner2 .titulo {
    font-size: 30px;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: 10px;
  }
  .bannerPrincipal_background img{
    height: 300px; /*570px;*/
    object-fit: cover;
    vertical-align: bottom;
  }
}
@media (max-width: 600px) {
  .bannerPrincipal_background img{
    object-position: 70%;
  }
  .banner2 .item-etiqueta{
    margin-bottom: 5px;
  }
  .bannerPrincipal_content {
    top: 10%;
    left: 10%;
    width: 80%;
    background: rgb(0,4,9);
    background: linear-gradient(0deg, rgba(0,4,9,1) 0%, rgba(0,7,9,0.8491771708683473) 65%, rgba(0,8,9,0.6671043417366946) 87%, rgba(0,8,9,0) 100%);
    z-index: 99;
    position: relative;
    left: 0;
    width: 100%;
    padding: 5%;
    margin-top: -400px;
    padding-bottom: 40px;
  }
  .bannerPrincipal_content img{
    width: 200px;
    margin-bottom: 25px;
  }
  .bannerPrincipal_content .titulo{
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 25px;
  }
  .bannerPrincipal_content .subtitulo{
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 25px;
  }
  .bannerPrincipal_btn{
    width: 90%;
    font-size: 12px;
    line-height: 15px;
    padding: 10px 20px;
  }
}


.banner2{
  height:504px
}
.banner2 .item-etiqueta{
  background: #CCCCCC;
  color: #000000;
  font-size: 13px;
  margin-right: 5px;
  padding: 3px 4px;
  border-radius: 3px;
}
.banner2 .etiqueta_tipo{
  background:#262632;
  color: #999999;
}
.banner2 .etiqueta_categoria{
  background: #000000;
  color: #CCCCCC;
}
.banner2 .titulo{
  font-size: 38px;
  font-weight: 500;
  margin-bottom: 10px;
}
.banner2 .destacado{
  margin-bottom: 20px;
}
.banner2_btn{
  background: #000;
  border: 1px solid #FFFFFF;
  border-radius: 5px;
  display: inline-block;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  padding: 10px 50px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}
.bannerPrincipal .homepublica_repuesto_btn{
  position: absolute;
  bottom:60px;
  left: 150px;
  z-index: 10;
}
@media (max-width: 800px) {
  .bannerPrincipal .homepublica_repuesto_btn {
    left: 10%;
  }
  .banner2{
    height: auto!important;
    top: 0!important;
    padding-top: 200px;
    margin-top: -510px;
  }
  button.slick-arrow {
    display: none!important;
  }
  .bannerInterior .bannerPrincipal_background img {
    height: 230px;
    object-fit: cover;
    vertical-align: unset;
    object-position: 0 50;
  }
  .bannerPrincipal_container{
    height: 230px;
  }
}
@media (max-width: 600px) {
  .bannerPrincipal .homepublica_repuesto_btn {
    width: 350px;
    bottom:120px;
  }
}
@media (max-width: 425px) {
  .bannerPrincipal .homepublica_repuesto_btn {
    width: 250px;
    bottom:30px;
  }
  .cookiemodal_buttons{
    margin-bottom: 10px;
  }
}

.slick-slider.slider-principal {

  background: #000;
}
.bannerPrincipal_container{
  position: absolute;
  top: 0;
  width: 100%;
  left: auto;
  display: flex;
  justify-content: center;
}
.claim-header{
  color: #fff;
  margin: 0;
  width: 100%;
  max-width: 100%;
  height: 565px;
  background: rgb(249,246,241);
  background: linear-gradient(90deg, rgba(249,246,241,1) 0%, rgba(249,246,241,1) 15%, rgba(249,246,241,1) 30%, rgba(249,246,241,0) 100%);
}
.claim-header .content {
  width: 40%;
  margin: 80px;
}

.claim-header b{
  color: #666;
}

.claim-header .titulo{
      font-size: 28px;
      margin-bottom: 15px;
      font-family: 'Roboto', sans-serif;
  }
  .claim-header .subtitulo{
    font-size: 24px;
    margin-bottom: 20px;
    line-height: 26px;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
  }
  .claim-header .boton{
      color:#fff;
      font-size: 28px;
      border: #fff solid 1px;
      padding: 5px 20px;
      margin-top: 20px;
      font-family: 'Lato', sans-serif;
      font-weight: 300;
  }
  .claim-header .titulo span{
    padding-right: 5px;
  }

@media (max-width: 576px) {  
  .claim-header{
    color:#fff;
    margin: 40px 10px;
    width: 100%;
    max-width: 550px;
  }
  .claim-header  .titulo{
        font-size: 28px;
        margin-bottom: 15px;
        font-family: 'Lato', sans-serif;
    }
    .claim-header .subtitulo{
      font-size: 24px;
      margin-bottom: 20px;
      line-height: 26px;
      font-family: 'Lato', sans-serif;
      font-weight: 300;
    }
    .claim-header .boton{
        color:#fff;
        font-size: 24px;
        border: #fff solid 1px;
        padding: 5px 20px;
        margin-top: 20px;
        font-family: 'Lato', sans-serif;
        font-weight: 300;
    }
}

@media (min-width: 768px) {  
  .claim-header{
    color:#fff;
    margin: 70px 0 100px 0;
    
  }
  .claim-header .titulo{
        font-size: 28px;
        margin-bottom: 15px;
        font-family: 'Roboto', sans-serif;
    }
    .claim-header  .subtitulo{
      font-size: 24px;
      margin-bottom: 20px;
      line-height: 26px;
      font-family: 'Lato', sans-serif;
      font-weight: 300;
    }
    .claim-header  .boton{
        color:#fff;
        font-size: 24px;
        border: #fff solid 1px;
        padding: 5px 20px;
        margin-top: 20px;
        font-family: 'Lato', sans-serif;
        font-weight: 300;
    }
}


@media (min-width: 992px) { 
  .claim-header{
    color:#000;
    margin: 0;
    max-width: 100%;
    font-weight: 300;
  }
    .claim-header .titulo{
        margin-bottom: 15px;
        font-family: 'Roboto', sans-serif;
        font-size: 115px;
        font-weight: 400;
        line-height: 115px;
    }
    
    .claim-header .subtitulo{
      font-size: 52px;
      margin-bottom: 20px;
      line-height: 54px;
      font-family: 'Lato', sans-serif;
      font-weight: 300;
      margin-top: 50px;
    }
    .claim-header .boton{
        color:#fff;
        font-size: 24px;
        border: #fff solid 1px;
        padding: 5px 20px;
        margin-top: 20px;
        font-family: 'Lato', sans-serif;
        font-weight: 300;
    }
    .claim-header .titulo span{
      padding-right: 20px;
    }
}

