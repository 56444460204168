.homepublica_canales{
  color: white;
  font-size: 2rem;
  font-weight: 600;
}
.homepublica_iconrow{
  flex-wrap: nowrap;
  padding: 0 1rem;
}
.homepublica_iconrow .titulo{
  line-height: 1.5rem;
}
.homepublica_iconrow_imgbox{
  width: 55px;
  height: 120px;
}
.homepublica_iconrow div{
  display: flex;
  justify-content: center;
}
.homepublica_iconrow div .col-3{
  display: flex;
  justify-content: center;
  align-items: center;
}
.sliderCanales{
  padding: 0px 25px;
}

.sliderCanales .titulo{
  text-align: center;
}

.sliderCanales .contenedorSlider{
  padding-top: 5px;
  padding-bottom: 5px;
}

.sliderCanales .slick-disabled{
  display: none!important;
}

.sliderCanales .item-slider{
  background: #262632 0% 0% no-repeat padding-box;
  border: 2px solid #262632;
  box-shadow: 0px 0px 20px #00000065;
  border-radius: 10px;
  max-width: 100%;
  text-align: center;
  padding: 30px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  opacity: .5;
}

.sliderCanales .slick-slide>div {
  padding: 0 5px;
  height: 100%;
}

.sliderCanales .slick-slide>div > div {
  height: 100%;
}

.sliderCanales .item-slider:hover{
  box-shadow: 0px 0px 25px #58A8F5;
  border: 2px solid #27E1F8CC;
  opacity: 1;
}

.sliderCanales  .slick-list {
  padding: 30px 0!important;
  display: flex;
  justify-content: center;
}

.slider-canales .slick-track
{
    display: flex !important;
}

.slider-canales .slick-slide
{
    height: inherit !important;
}
.sliderCanales .slick-list {margin: 0 -5px; }
.sliderCanales .slick-slide>div {padding: 0 5px;}
.sliderCanales .slick-slide img {
  display: block;
  width: 100%;
  height: auto;
}
@media (max-width: 1024px) {
  .sliderCanales  .slick-list {
    display: block;
  }
}
@media (max-width: 600px) {
  .contenedorSlider {
    margin:0 15px;
  }
  .homepublica_canales{
    font-size: 1.5rem;
  }
}