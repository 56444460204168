.baseModal {
    width: 100%;
    height: 100vh;
    top:0;
    left:0;
    background-color: rgba(11, 13, 26, .80);
    position: fixed;
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.baseModal.open{
    display: flex;
}

.modalBody{
    position: relative;
    max-width: 480px;
    max-height: 70vh;
    padding: 50px;
    background-color: #0B0D1A;
    border:1px solid #707070 ;
    border-radius: 5px;
    box-sizing: content-box;
    margin: 0 15px;
}
.modalBody .modalLogin_cerrar{
    position: absolute;
    top:-28px;
    right: 0;
    font-size: 0.65rem;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
}
.modalLogin_logo img{
    width: 100%;
    height: auto;
    vertical-align: bottom;
}
.modalBody .modalX .titulo{
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 20px;
}

.modalBody .tituloheader{
    text-align: center;
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 25px;
}
.modalBody.modalLogin .form-group{
    margin-bottom: 15px;
}

.helperLogin{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.helperLogin .small{
    cursor: pointer;
}
.leyendaForm{
    color: #888;
    font-size: 13px;
    margin-bottom: 15px;
}


.small{
    font-size: 12px;
}

label.small{
    font-size: 14px;
}

.inputText{
    height: 60px;
    background: #707070;
    border-radius: 5px;
    border: none;
    margin: 10px 0;
    color: #fff;
    font-size: 16px;
    padding: 0 15px;
    width: 100%;
}
.inputMail{
    text-transform: lowercase;
}
.inputEmpresa{
    text-transform: uppercase;
}

.labelInput{
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    color: #FFF;
}
.boton_iniciarsesion{
    background-color: #bbf1a0;
    width: 100%;
    border-radius: 5px;
    border: none;
    padding: 1.3rem 0;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1rem;
}
/* width */
.modalBody::-webkit-scrollbar {
    width: 10px;
}
/* Track */
.modalBody::-webkit-scrollbar-track {
    background: #f1f1f1; 
}
/* Handle */
.modalBody::-webkit-scrollbar-thumb {
    background: #888; 
}
/* Handle on hover */
.modalBody::-webkit-scrollbar-thumb:hover {
    background: #555; 
}
.modalBody .btn.principal{
    width: 100%;
}
.modalXbody{
    padding: 50px 70px;
    text-align: left;
}
.modalX p{
    color: white;
    margin-bottom: 20px;
    line-height: 22px;
}
.modalX span{
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 22px;
}
.modalX .btn.transparente{
    width: 100%;
    padding: 15px;
}
.step3Inscripcion .row{
   margin: 0;
}
@media (max-width: 600px) {
    .modalBody .modalLogin_cerrar{
        top:10px;
        right: 10px;
    }
    .modalBody .tituloheader{
        font-size: 25px;
        margin-bottom: 10px;
    }
    .modalBody .titulo{
        font-size: 22px;
        line-height: 1.5rem;
    }
    .modalBody .labelInput{
        font-size: 14px;
    }
  }

  .modalBody.modalRegistroPresencial{
      max-height: inherit;
  }

  .modalRegistroPresencial .modalLogin_logo {
    width: 200px;
    margin-top: 60px;
    margin: 0 auto 40px auto;
    margin-top: 80px;
}

.modalRegistroPresencial .step3 {
    max-height: 53vh;
    overflow: auto;
    padding: 5px;
    padding-right: 1rem;
    padding-bottom: 80px;
    text-align: left;
}