.cookiemodal{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 100px;
    width: 100%;
    background-color: black;
    z-index: 9999;
}
.cookiemodal_msg{
    margin-right: 3rem;
    margin-bottom: 25px;
}
.cookiemodal_msg p{
    font-size: .9rem;
}
.cookiemodal_msg p a{
    color: #3B619E;
    text-decoration: underline;
    cursor: pointer;
}
.cookiemodal_buttons{
    margin-bottom: 33px;
}
.cookiemodal_buttons button{
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: .7rem;
    text-transform: uppercase;
    padding: .5rem .8rem;
    border: none;
    border-radius: 5px;
    margin-right: 1rem;
    background-color: #262626;
    color: white;
    cursor: pointer;
}
.cookiemodal_buttons .aceptar{
    background-color: #54a6f8;
}
@media (max-width: 960px) {
    .cookiemodal_msg p{
        font-size: .8rem;
    }
}
@media (max-width: 890px) {
    .cookiemodal{
        height: 140px;
    }
    .cookiemodal_msg{
        margin-bottom: 40px;
    }
    .cookiemodal_buttons{
        display: flex;
        flex-direction: column;
    }
    .cookiemodal_buttons button:first-of-type{
        margin-bottom: 0.5rem;
    }
}
@media (max-width: 790px) {
    .cookiemodal{
        flex-direction: column;
        align-items: flex-start;
        padding-left: 2rem;
        height: 160px;
    }
    .cookiemodal_msg{
        margin-bottom: 0.5rem;
        margin-right: 0;
    }
    .cookiemodal_msg p{
        line-height: 0.9rem;
    }
    .cookiemodal_buttons{
        flex-direction: row;
        margin-bottom: 0;
    }
    .cookiemodal_buttons button:first-of-type{
        margin-bottom: 0;
    }
}
@media (max-width: 360px) {
    .cookiemodal{
        height: auto;
    }
    .cookiemodal_msg{
        padding: 10px 0;
    }
}