.video-resume {
    position: absolute;
    z-index: 15;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,.8);
    color: #fff;
    flex-direction: column;
    display: none;
}

.video-resume.active {
    display: flex;
}

.btn-resume{
    width: 300px;
    padding: .8rem;
    border-radius: 5px;
    background-color: black;
    border: 1px solid white;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 1rem;
    font-size: 1rem;
    cursor: pointer;
}
.btn-resume2{
    width: 300px;
    padding: .8rem;
    border-radius: 5px;
    background-color: #4A4A4A;
    color: #999999;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 1rem;
    font-size: 1rem;
    cursor: pointer;
}
.play_btn{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    cursor: pointer;
}
.play_btn_hide{
    display: none;
}
.play_btn svg{
    color: white;
    width: 65px;
    height: auto;
}
.btn-play-bg {
  background: #3B619E;
  width: 150px;
  height: 90px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.play_btn svg path{
  fill: #fff;
  width: 150px;
}
.video-js .vjs-big-play-button{
    display: none;
}
@media (max-width: 500px) {
    .play_btn svg{
        width: 80px;
    }
    .btn-resume{
        width: 70%;
        font-size: 0.9rem;
        padding: .5rem;
    }
    .btn-resume2{
        width: 70%;
        font-size: 0.9rem;
        padding: .5rem;
    }
  }
@media (max-width: 400px) {
    .btn-resume{
        width: 80%;
        font-size: 0.7rem;
        padding: .4rem;
    }
    .btn-resume2{
        width: 80%;
        font-size: 0.7rem;
        padding: .4rem;
    }
  }



.evento_online_main_content .container-img{
    border: 2px solid #70707077;
    border-radius: 5px;
    box-shadow: 0px 0px 20px #00000065;
    position: relative;
  }
  .evento_online_main_content .anuncio_emision{
    display: flex;
    position: absolute;
    bottom: 5%;
    left: 2%;
    background-color: rgba(0, 0, 0, .8);
    font-size: 18px;
    padding: 20px;
    text-align: left;
    border:2px solid #707070;
    border-radius: 5px;
  }
  .evento_online_main_content .anuncio_emision img{
    width: 50px;
    height: 50px;
    margin-right: 25px;
  }
  .evento_online_main_content .anuncio_emision p{
    color: white;
    margin-bottom: 5px;
  }
  .evento_online_main_content .anuncio_emision span{
    color: #3B619E;
  }
.container-img .anuncio_directo{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 2;
    top: 5%;
    left: 3%;
    padding: 10px;
    height: 40px;
    background-color: #FA1900;
    border-radius: 5px;
}
.container-img .anuncio_directo img{
    width: 25px;
    height: 25px;
    margin-right: 13px;
    margin-bottom: 3px;
}
.container-img .anuncio_directo p{
    margin-bottom: 0;
    font-size: 16px;
    color: white;
    text-transform: uppercase;
    font-weight: 500;
    user-select: none;
    margin-top: 2px;
}
@media (max-width: 1530px) {
    .evento_online_main_content .anuncio_emision{
        font-size: 16px;
      }
      .evento_online_main_content .anuncio_emision p{
        font-size: 16px;
      }
    .evento_online_main_content .anuncio_emision img{
        width: 45px;
        height: 45px;
      }

      .container-img .anuncio_directo img{
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }
    .container-img .anuncio_directo p{
        font-size: 14px;
    }
  }
@media (max-width: 1330px) {
    .evento_online_main_content .anuncio_emision{
        font-size: 14px;
      }
      .evento_online_main_content .anuncio_emision p{
        font-size: 14px;
      }
    .evento_online_main_content .anuncio_emision img{
        width: 40px;
        height: 40px;
      }

      .container-img .anuncio_directo{
        padding: 8px;
    }
      .container-img .anuncio_directo img{
        width: 18px;
        height: 18px;
        margin-right: 8px;
    }
    .container-img .anuncio_directo p{
        font-size: 12px;
        margin-bottom: 1px;
    }
  }
@media (max-width: 1230px) {
    .evento_online_main_content .anuncio_emision{
        font-size: 13px;
        border:1px solid #707070;
      }
      .evento_online_main_content .anuncio_emision p{
        font-size: 13px;
      }
    .evento_online_main_content .anuncio_emision img{
        width: 35px;
        height: 35px;
      }
  }
  @media (max-width: 1100px) {
    .evento_online_main_content .anuncio_emision{
        font-size: 16px;
        border:2px solid #707070;
      }
      .evento_online_main_content .anuncio_emision p{
        font-size: 16px;
      }
    .evento_online_main_content .anuncio_emision img{
        width: 45px;
        height: 45px;
      }

    .container-img .anuncio_directo{
        padding: 10px;
    }
    .container-img .anuncio_directo img{
        width: 25px;
        height: 25px;
        margin-right: 13px;
        margin-bottom: 1px;   
    }
    .container-img .anuncio_directo p{
        font-size: 16px;
    }
  }
  @media (max-width: 800px) {
    .evento_online_main_content .anuncio_emision{
        font-size: 14px;
      }
      .evento_online_main_content .anuncio_emision p{
        font-size: 14px;
      }
    .evento_online_main_content .anuncio_emision img{
        width: 40px;
        height: 40px;
      }

    .container-img .anuncio_directo{
        padding: 8px;
    }
    .container-img .anuncio_directo img{
        width: 20px;
        height: 20px;
        margin-right: 10px;
        margin-bottom: 1px;   
    }
    .container-img .anuncio_directo p{
        font-size: 14px;
    }
  }
  @media (max-width: 700px) {
    .evento_online_main_content .anuncio_emision{
        font-size: 13px;
        border:1px solid #707070;
      }
      .evento_online_main_content .anuncio_emision p{
        font-size: 13px;
      }
    .evento_online_main_content .anuncio_emision img{
        width: 35px;
        height: 35px;
      }

      .container-img .anuncio_directo{
        padding: 5px;
    }
    .container-img .anuncio_directo p{
        font-size: 12px;
    }
  }
  @media (max-width: 530px) {
    .evento_online_main_content .anuncio_emision{
        font-size: 10px;
        border:1px solid #707070;
        padding: 12px;
      }
      .evento_online_main_content .anuncio_emision p{
        font-size: 10px;
        margin-bottom: 1px;
      }
    .evento_online_main_content .anuncio_emision img{
        width: 25px;
        height: 25px;
        margin-right: 10px;
      }

      .container-img .anuncio_directo{
        padding: 5px;
    }
    .container-img .anuncio_directo img{
        width: 16px;
        height: 16px;
        margin-right: 6px; 
    }
    .container-img .anuncio_directo p{
        font-size: 11px;
    }
  }
