.caja-item-ciudad {
    background: transparent;
    border-radius: 5px;
   
    text-align: left;
    overflow: hidden;
    max-width: 360px;
}

.caja-item-ciudad .caja-item-titulo{
    color: #DDDDDD;
    cursor: pointer;
    font-size: 14px;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    min-height: 54px;
}

.caja-item-ciudad .caja-item-contendor-imagen{
    flex-wrap: wrap;
    overflow: hidden;
    position: relative;
    display: flex;
    margin-bottom: 20px;
    
}


.caja-item-ciudad .caja-item-contendor-imagen .caja-item-etiqueta {
    opacity: .9;
    position: absolute;
    margin: 10px;
}

.caja-item-ciudad .caja-item-imagen{
    width: 360px;
    height: auto;
    max-width: 360px;
    box-shadow: 0px 0px 20px #00000065;
}

.caja-item-ciudad .caja-item-body {
    margin-bottom: 10px;
    padding: 0 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.caja-item-ciudad .caja-item-contendor-imagen .caja-item-etiqueta{
    color:#000000B3;
}

.caja-item-ciudad .caja-item-etiquetas {
    display: flex;
    margin: 10px 0;
    flex-wrap: wrap;
}

.caja-item-ciudad .caja-item-etiqueta{
    background: #CCCCCC;
    color: #000000;
    font-size: 12px;
    margin-right: 10px;
    padding: 2px 3px;
    border-radius: 3px;
    margin-bottom: 10px;
}


.caja-item-ciudad .caja-item-body .titulo-ciudad{
    font-size: 38px;
    color:#FFFFFF;
    margin-bottom: 15px;
}

.caja-item-ciudad .caja-item-body .titulo-fecha{
    font-size: 27px;
    color:#3B619E;
    margin-bottom: 10px;
}

.caja-item-ciudad .caja-item-body .titulo-lugar{
    font-size: 18px;
    color: #DDDDDD;
    margin-bottom: 10px;
}


.caja-item-ciudad .caja-item-footer {
    padding: 10px 20px 10px 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
